import { FileExcelFilled } from '@ant-design/icons';
import { Col, Button, Tooltip } from 'antd';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import useGroup from '../../../zustand/useGroup';

interface IProps {
  columnsFiltered: any[];
  dataSource: any[];
  loading: boolean;
}

const ExportDataReport: React.FC<IProps> = ({
  columnsFiltered,
  dataSource,
  loading,
}) => {
  const { asset, filter } = useGroup();

  async function onDownloadXLSX() {
    let currentColumns: string[] = [];

    if (columnsFiltered && columnsFiltered.length) {
      columnsFiltered.forEach((el: any) => {
        if (el.children && el.children.length) {
          currentColumns = [
            ...currentColumns,
            ...el.children.map((item: any) => item.key),
          ];
        } else {
          currentColumns.push(el.key);
        }
      });
    }

    if (dataSource && dataSource.length) {
      const mappedDataSource: any[] = dataSource.map((item: any) => {
        const filteredItem: any = {};
        currentColumns.forEach((key) => {
          if (key in item) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      let colsWidth = mappedDataSource
        .reduce((acc: any, row: any) => {
          let values = Object.values(row);

          values.forEach((value: any, index) => {
            if (index === 0 || index === 1 || index === 2) {
              acc[index] = Math.max(acc[index] || 0, value ? value.length : 0);
            } else {
              acc[index] = 20;
            }
          });

          return acc;
        }, [])
        .map((width: any) => ({ width }));

      const data = XLSX.utils.json_to_sheet(mappedDataSource);
      data['!cols'] = colsWidth; //set panjang kolom
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      const startDate = dayjs(filter.startAt * 1000).format('DD-MMM-YY-HHmm');
      const endDate = dayjs(filter.endAt * 1000).format('DD-MMM-YY-HHmm');
      XLSX.writeFile(
        wb,
        `${asset.name.split(' ').join('_')}-${startDate}_${endDate}.xlsx`
      );
    }
  }

  return (
    <Tooltip title='Download report log as .XLSX based on applied filter'>
      <Button
        color='success'
        disabled={loading}
        onClick={() => onDownloadXLSX()}
        type='primary'
        icon={<FileExcelFilled />}
      >
        Export Report Log
      </Button>
    </Tooltip>
  );
};

export default ExportDataReport;
