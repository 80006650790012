import { useEffect, useMemo, useState } from 'react';
import {
  convertData,
  convertIntervalToSecond,
  generateTimestampData,
  latToDMS,
  longToDMS,
  toHHMMSS,
  toKM,
  toKnot,
  toNM,
} from './helperFunction';
import { generateReportLogColumns } from './tableColumn';
import useGroup from '../../../zustand/useGroup';
import useReportLog from '../../../hooks/useReportLog';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  MenuProps,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import dayjs from 'dayjs';
import {
  ArrowDownOutlined,
  CaretDownOutlined,
  SettingFilled,
} from '@ant-design/icons';
import React from 'react';
import TableConfiguration from './tableConfiguration';
import ReportSummary from './summary';
import { EReportDataInterval } from '../types/report-log.type';
import ImportDataReport from './importDataReport';
import ExportDataReport from './exportDataReport';
import usePermission from '../../../hooks/usePermission';
import styled from 'styled-components';
import {
  distanceUnitOptions,
  literUnitOptions,
  speedUnitOptions,
} from '../../../helpers/unit';
import { useVT } from 'virtualizedtableforantd4';
import { degreesToRadians } from '../../../helpers/map-util';

const windowHeight = window.innerHeight;
export interface RowData {
  [key: string]: string | number | null;
}

const TableReportLog = () => {
  const { isUserHasPermission } = usePermission();
  const { filter } = useGroup();
  const {
    setChecklistColumn,
    checklistColumn,
    loadingLog: loading,
    logs,
    setSpeedUnit,
    setDistanceUnit,
    distanceUnit,
    setLiterUnit,
    literUnit,
    speedUnit,
  } = useReportLog();
  const [columns, setColumns] = useState<any>([]);
  const [vt] = useVT(() => ({ scroll: { x: 700, y: windowHeight - 300 } }), []);

  useEffect(() => {
    if (logs && Object.keys(logs).length) {
      const res = generateReportLogColumns(logs).map((item: any) => {
        if (item.children) {
          return {
            ...item,
            hidden: false,
            children: item.children.map((el: any) => {
              return {
                ...el,
                hidden: false,
              };
            }),
          };
        } else {
          return {
            ...item,
            hidden: false,
          };
        }
      });

      setColumns(res);
    }
  }, [logs]);

  const handleChangeUnit = (e: any, unitType: string) => {
    if (unitType === 'speed') {
      setSpeedUnit(e.key);
    } else if (unitType === 'distance') {
      setDistanceUnit(e.key);
    } else if (unitType === 'liter') {
      setLiterUnit(e.key);
    }
  };

  useEffect(() => {
    if (columns && columns.length) {
      const checklist: string[] = [];

      columns.forEach((item: any) => {
        if (item.children) {
          checklist.push(item.key);
          item.children.forEach((child: any) => {
            checklist.push(child.key);
          });
        } else {
          checklist.push(item.key);
        }
      });

      setChecklistColumn(checklist);
    }
  }, [columns]);

  const columnsFiltered = useMemo(() => {
    const res = columns
      .filter((item: any) => {
        if (checklistColumn.includes(item.key)) {
          return item;
        } else if (item.children) {
          const isParentExist = checklistColumn.find(
            (columnKey) => item.key === columnKey
          );
          if (isParentExist) {
            return {
              ...item,
              children: item.children.filter((child: any) =>
                checklistColumn.includes(child.key)
              ),
            };
          }
        }
      })
      .map((item: any, idx: number) => {
        if (item.children && item.children.length) {
          return {
            ...item,
            children: item.children.map((child: any) => {
              if (child.title.toLowerCase() === 'speed') {
                return {
                  ...child,
                  title: () => (
                    <div>
                      <span>{child.title}</span>
                      <Dropdown
                        menu={{
                          items: speedUnitOptions.map((item) => ({
                            key: item.value,
                            label: item.label,
                          })),
                          onClick: (e) => handleChangeUnit(e, 'speed'),
                        }}
                      >
                        <TitleChild>
                          <Unit>({speedUnit})</Unit>
                          <CaretDownOutlined style={{ opacity: 0.5 }} />
                        </TitleChild>
                      </Dropdown>
                    </div>
                  ),
                };
              } else if (child.title.toLowerCase() === 'distance') {
                return {
                  ...child,
                  title: () => (
                    <div>
                      <span>{child.title}</span>
                      <Dropdown
                        menu={{
                          items: distanceUnitOptions.map((item) => ({
                            key: item.value,
                            label: item.label,
                          })),
                          onClick: (e) => handleChangeUnit(e, 'distance'),
                        }}
                      >
                        <TitleChild>
                          <Unit>({distanceUnit})</Unit>
                          <CaretDownOutlined style={{ opacity: 0.5 }} />
                        </TitleChild>
                      </Dropdown>
                    </div>
                  ),
                };
              } else if (child.title.toLowerCase() === 'in flow') {
                return {
                  ...child,
                  title: () => (
                    <div>
                      <span>{child.title}</span>
                      <Dropdown
                        menu={{
                          items: literUnitOptions.map((item) => ({
                            key: item.value,
                            label: item.label,
                          })),
                          onClick: (e) => handleChangeUnit(e, 'liter'),
                        }}
                      >
                        <TitleChild>
                          <Unit>({literUnit})</Unit>
                          <CaretDownOutlined style={{ opacity: 0.5 }} />
                        </TitleChild>
                      </Dropdown>
                    </div>
                  ),
                };
              }
              return child;
            }),
          };
        } else {
          return item;
        }
      });
    return res;
  }, [columns, checklistColumn, speedUnit, literUnit, distanceUnit]);

  const dataSource = useMemo(() => {
    if (logs && Object.keys(logs).length) {
      let intervalInSecond = convertIntervalToSecond(filter.interval);

      const containerTimestamp = generateTimestampData({
        startTime: filter.startAt,
        endTime: filter.endAt,
        interval: filter.interval,
        intervalInSecond,
      });

      const results = containerTimestamp.map((timestamp) => {
        let fm;
        let rpm;
        let ae;
        let gps;
        if (logs.flowmeter) {
          const foundKey = Object.keys(logs.flowmeter).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            fm = logs.flowmeter[foundKey];
          }
        }
        if (logs.rpm) {
          const foundKey = Object.keys(logs.rpm).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            rpm = logs.rpm[foundKey];
          }
        }
        if (logs.ae) {
          const foundKey = Object.keys(logs.ae).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            ae = logs.ae[foundKey];
          }
        }
        if (logs.gps) {
          const foundKey = Object.keys(logs.gps).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            gps = logs.gps[foundKey];
          }
        }

        let gps_speed = 0;
        let gps_distance = toKM(gps?.distance || 0);
        let port_InFlow = fm?.portIn?.volumeFlowrate;
        let starboard_InFlow = fm?.starboardIn?.volumeFlowrate;

        if (speedUnit === 'KNOT' && gps?.speed) {
          gps_speed = toKnot(gps.speed);
        } else {
          gps_speed = gps?.speed || 0;
        }

        if (distanceUnit === 'KM' && gps?.distance) {
          gps_distance = toKM(gps.distance);
        } else if (distanceUnit === 'NM' && gps?.distance) {
          gps_distance = toNM(gps.distance);
        } else {
          gps_distance = gps?.distance || 0;
        }

        if (literUnit === 'Liter/Min') {
          port_InFlow = port_InFlow ? port_InFlow / 60 : port_InFlow;
          starboard_InFlow = starboard_InFlow
            ? starboard_InFlow / 60
            : starboard_InFlow;
        }

        return {
          timestamp,
          dateTime: dayjs(timestamp * 1000).format('DD MMM YYYY HH:mm'),
          gps_coordinate:
            gps?.latitude && gps?.longitude
              ? `${latToDMS(gps?.latitude)}, ${longToDMS(gps?.longitude)}`
              : '-',
          gps_latitude: gps?.latitude,
          gps_longitude: gps?.longitude,
          gps_heading: degreesToRadians(gps?.heading),
          gps_speed: convertData(gps_speed),
          gps_distance: convertData(gps_distance),
          port_Rpm: convertData(rpm?.port?.rpm),
          port_RunningTime: toHHMMSS(rpm?.port?.runningTime),
          port_FuelCons: convertData(fm?.deviceCalculation?.portIn?.fuelCons),
          port_VolumeTotal: convertData(
            fm?.deviceCalculation?.portIn?.volumeFuelCons
          ),
          port_InFlow: convertData(port_InFlow),
          port_Temperature: convertData(fm?.portIn?.temperature),
          port_InDensity: convertData(fm?.portIn?.density),
          starboard_Rpm: convertData(rpm?.starboard?.rpm),
          starboard_RunningTime: toHHMMSS(rpm?.starboard?.runningTime),
          starboard_FuelCons: convertData(
            fm?.deviceCalculation?.starboardIn?.fuelCons
          ),
          starboard_VolumeTotal: convertData(
            fm?.deviceCalculation?.starboardIn?.volumeFuelCons
          ),
          starboard_InFlow: convertData(starboard_InFlow),
          starboard_Temperature: convertData(fm?.starboardIn?.temperature),
          starboard_InDensity: convertData(fm?.starboardIn?.density),
          ae1_RunningTime: toHHMMSS(ae?.ae1?.runningTime),
          ae1_FuelConsumption: convertData(ae?.ae1?.fuelConsumption),
          ae2_RunningTime: toHHMMSS(ae?.ae2?.runningTime),
          ae2_FuelConsumption: convertData(ae?.ae2?.fuelConsumption),
          ae3_RunningTime: toHHMMSS(ae?.ae3?.runningTime),
          ae3_FuelConsumption: convertData(ae?.ae3?.fuelConsumption),
        };
      });

      return results;
    }
  }, [logs, speedUnit, distanceUnit, literUnit]);

  return (
    <React.Fragment>
      <ReportSummary defaultOpenBody={['body1', 'body2']} />

      <Divider />

      <ContainerFilterTable>
        <Wrapper>
          <TableConfiguration columns={columns} />
        </Wrapper>
        <Wrapper>
          <ExportDataReport
            columnsFiltered={columnsFiltered}
            dataSource={dataSource || []}
            loading={loading}
          />
          {isUserHasPermission(['IMPORT_DATA_LOG' + '.CREATE']) && (
            <ImportDataReport />
          )}
        </Wrapper>
      </ContainerFilterTable>

      <Table
        loading={loading}
        className='table-report-log'
        columns={columnsFiltered}
        dataSource={dataSource}
        size='small'
        bordered
        pagination={{
          pageSize: 500,
        }}
        components={vt}
        scroll={{ x: 700, y: windowHeight - 300 }}
      />
    </React.Fragment>
  );
};

const ContainerFilterTable = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleChild = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  gap: 5px;
`;

const Unit = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export default TableReportLog;
