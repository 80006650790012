import {
  AppTable,
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  IAppTableColumn,
  IHttpResponse,
  IPayloadPagination,
  generateFormRules,
  generateQueryString,
  getErrorMessage,
  getStandardError,
} from '@qlibs/react-components';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TableColumnsType,
  TableProps,
  TimePicker,
  Tooltip,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from '../../user/components/CustomFormItem';
import { httpRequest } from '../../../helpers/api';
import SectionContent from '../../../components/SectionContent';
import { AssetProperties } from '../../asset/types/asset.type';
import {
  ICreateReportEmailReceiver,
  ICreateReportEmailTargetAsset,
  ReportEmailReceiverProperties,
  ReportEmailTargetAssetProperties,
  ReportEmailTargetProperties,
  ReportEmailTemplateProperties,
} from '../types/report.type';
import useMasterData from '../../../hooks/useMasterData';
import { UserProperties } from '../../../services/openapi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Jakarta');

interface ILocation {
  reportEmailTargetAssetId: string;
}

interface ITargetEmail {
  key: React.Key;
  name: string;
  email: string;
  userId: string;
  roleName: string;
}

const MasterReportEmailTargetAssetEdit = () => {
  const navigate = useNavigate();
  const { reportEmailTargetAssetId } = useParams<
    keyof ILocation
  >() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [data, setData] = useState<ReportEmailTargetAssetProperties>();
  const [templateEmails, setTemplateEmails] = useState<
    ReportEmailTemplateProperties[]
  >([]);
  const [selectedEmails, setSelectedEmails] = useState<any[]>([]);
  const [usersOrEmails, setUsersOrEmails] = useState<ITargetEmail[]>([]);
  const [email, setEmail] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('');
  const [form] = Form.useForm();

  const {
    companies,
    assets,
    getListCompanies,
    getListAssets,
    loadingAsset,
    loadingCompany,
  } = useMasterData();

  async function updateReportEmailReceiver(
    values: ICreateReportEmailTargetAsset
  ) {
    try {
      setIsLoadingAction(true);
      let sendingAt = '';
      if (values.frequency === 'DAILY') {
        const hour = dayjs(values.sendingAt).get('hour');
        const minute = dayjs(values.sendingAt).get('minute');
        sendingAt = dayjs()
          .set('hour', hour)
          .set('minute', minute)
          .set('second', 0)
          .toISOString();
      }

      const formData = {
        companyId: values.companyId,
        assetId: values.assetId,
        templateId: values.templateId,
        sendingAt,
        timeFrom: dayjs(values.timeFrom).format('HH:mm:ss'),
        timeTo: dayjs(values.timeTo).format('HH:mm:ss'),
        frequency: values.frequency,
        dayOfWeek: values.dayOfWeek,
        dateOfMonth: values.dateOfMonth,
        dataThresholdMin: 98,
        userIdOrEmails: usersOrEmails.filter(
          (item) =>
            selectedEmails.includes(item.email) && {
              email: item.email,
              userId: item.userId,
            }
        ),
        isUpdateUserIdOrEmails: reportEmailTargetAssetId ? true : false,
        status: values.status ? 'active' : 'inactive',
      };

      if (reportEmailTargetAssetId) {
        await httpRequest.patch<
          BaseResponseProps<ReportEmailTargetAssetProperties>
        >('/report-email-target-asset/' + reportEmailTargetAssetId, formData);
      } else {
        await httpRequest.post<
          BaseResponseProps<ReportEmailTargetAssetProperties>
        >('/report-email-target-asset', formData);
      }

      message.success(`Success ${reportEmailTargetAssetId ? 'edit' : 'add'}`);
      setData(undefined);
      form.resetFields();
      navigate('/master-report');
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning('This data has been archived and cannot be edited');
    } else {
      updateReportEmailReceiver(values);
    }
  };

  async function fetchData(reportEmailTargetAssetId: string) {
    try {
      setIsLoadingAction(true);
      const response = await httpRequest.get<
        BaseResponseProps<ReportEmailTargetAssetProperties>
      >('/report-email-target-asset/' + reportEmailTargetAssetId);
      if (response.data.payload) {
        const data: any = response.data.payload;

        setData(data);
        setCompanyId(data.companyId);

        const formData = {
          ...data,
          sendingAt: dayjs(data.sendingAt).tz('Asia/Jakarta'),
          timeFrom: dayjs(data.timeFrom, 'HH:mm:ss'),
          timeTo: dayjs(data.timeTo, 'HH:mm:ss'),
          status: data.status === 'active' ? true : false,
        };

        setFrequency(data.frequency);

        setSelectedEmails(
          data.reportEmailTarget.map((item: any) => item.user.email)
        );
        // setUsersOrEmails((prev) => [
        //   ...data.reportEmailTarget.map((item: any) => ({
        //     userId: item.userId || null,
        //     email: item.user?.email || item.email,
        //     name: item.user?.name || item.name,
        //   })),
        // ]);

        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get report email receiver`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  const fetchTemplate = async (params: any) => {
    try {
      setIsLoadingAction(true);
      const res = await httpRequest.get(
        `/template-messages${generateQueryString(params)}`
      );

      if (res.data.payload.results) {
        setTemplateEmails(res.data.payload.results);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  // const fetchEmailReceiver = async (params: any) => {
  //   try {
  //     const res = await httpRequest.get(
  //       `/report-email-receiver${generateQueryString(params)}`
  //     );

  //     if (res.data.payload.results) {
  //       setEmailReceivers(res.data.payload.results);

  //       setUsersOrEmails((prev) => [
  //         ...prev,
  //         ...res.data.payload.results
  //           .filter(
  //             (item: any) =>
  //               !prev.some((prevItem) => prevItem.userId === item.userId)
  //           )
  //           .map((item: ReportEmailReceiverProperties) => ({
  //             userId: item.userId,
  //             email: item.user?.email,
  //             name: item.user?.name,
  //           })),
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error(getErrorMessage(error));
  //   }
  // };

  const fetchUserCompany = async (params: any) => {
    try {
      setIsLoadingAction(true);
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<UserProperties>>
      >(`/users${generateQueryString(params)}`);

      if (res.data.payload.results) {
        // setEmailReceivers(res.data.payload.results);

        setUsersOrEmails(() =>
          res.data.payload.results.map((item) => ({
            key: item.email,
            name: item.name,
            email: item.email,
            userId: item.userId,
            roleName: item.role?.roleName || 'N/A',
          }))
        );
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleSelectedEmail = (val: any) => {
    const res = selectedEmails.find((item) => item === val);

    if (!res) {
      setSelectedEmails((prev) => [...prev, val]);
    } else {
      setSelectedEmails((prev) => prev.filter((item) => item !== val));
    }
  };

  const columnsEmail: TableColumnsType<ITargetEmail> = [
    // {
    //   title: '',
    //   key: 'isSelected',
    //   render: (value, records) => {
    //     const isChecked = !!userIdOrEmails.find((val) => {
    //       const data = records.userId ? records.userId : records.email;

    //       return val === data;
    //     });

    //     return (
    //       <Checkbox
    //         checked={isChecked}
    //         onChange={() => {
    //           handleSelectedEmail(
    //             `${records?.userId ? records?.userId : records?.email}`
    //           );
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (value) => value || 'N/A',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (value) => value || 'N/A',
    },
    {
      title: 'ROLE',
      dataIndex: 'roleName',
      key: 'roleName',
      render: (value) => value || 'N/A',
    },
    // {
    //   title: 'ACTION',
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (value, records) => {
    //     if (!records.userId) {
    //       return (
    //         <Button
    //           danger
    //           onClick={() => {
    //             setUsersOrEmails((prev) =>
    //               prev.filter((item) => item.email !== records.email)
    //             );
    //             setUserIdOrEmails((prev) =>
    //               prev.filter((item) => item !== records.email)
    //             );
    //           }}
    //         >
    //           Delete
    //         </Button>
    //       );
    //     }
    //   },
    // },
  ];

  const companyMapped = React.useMemo(() => {
    return companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
  }, [companies]);

  const templateEmailMapped = React.useMemo(() => {
    return templateEmails.map((item) => ({
      value: item.templateId,
      label: item.templateName,
    }));
  }, [templateEmails]);

  const assetMapped = React.useMemo(() => {
    return assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
  }, [assets]);

  useEffect(() => {
    if (reportEmailTargetAssetId) {
      fetchData(reportEmailTargetAssetId);
    }
  }, [reportEmailTargetAssetId]);

  useEffect(() => {
    getListCompanies();
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchTemplate({});
      getListAssets({ filterCompanyIds: companyId });
      fetchUserCompany({ companyId });
      // fetchEmailReceiver({ filterCompanyId: companyId });
    }
  }, [companyId]);

  // useEffect(() => {
  //   setUsersOrEmails((prev) => [...prev, {userId: null, email}])
  // }, [userIdOrEmails]);

  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  );

  const rowSelection: TableProps<ITargetEmail>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ITargetEmail[]) => {
      console.log(
        `rex`,
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
      setSelectedEmails(selectedRowKeys);
    },
    getCheckboxProps: (record: ITargetEmail) => ({
      name: record.name,
    }),
    selectedRowKeys: selectedEmails,
  };

  const loading = useMemo(() => {
    if (isLoadingAction || loadingAsset || loadingCompany) {
      return true;
    }

    return false;
  }, [isLoadingAction, loadingAsset, loadingCompany]);

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={`${
          reportEmailTargetAssetId ? 'Update' : 'Add'
        } Master Report Email Target Asset`}
        subtitle={`${
          reportEmailTargetAssetId ? 'Update' : 'Add'
        } current master report email target asset`}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Spin spinning={loading} tip='Loading data...'>
        <Card>
          {data?.deletedAt ? (
            <Alert
              className='mb-5'
              type='warning'
              message='This data has been archived and cannot be edited'
            />
          ) : (
            false
          )}
          <Form
            form={form}
            name='profileForm'
            layout='vertical'
            onFinish={handleSubmit}
            autoComplete='off'
          >
            <SectionContent
              groupTitle='Information'
              subTitle='Detail information about email receiver'
            >
              <Row>
                <CustomFormItemPer2
                  label='Company'
                  name='companyId'
                  rules={generateFormRules('Company', ['required'])}
                >
                  <Select
                    showSearch
                    placeholder='Choose company'
                    style={{ width: '100%' }}
                    options={companyMapped}
                    onChange={(e) => {
                      setCompanyId(e);

                      form.setFieldsValue({
                        assetId: undefined,
                        templateId: undefined,
                      });

                      // setUsersOrEmails((prev) => [
                      //   ...prev
                      //     .filter((item) => !item.userId)
                      //     .map((item: any) => ({
                      //       userId: null,
                      //       email: item.email,
                      //       name: item.name,
                      //     })),
                      // ]);

                      // setUserIdOrEmails((prev) => [
                      //   ...prev.filter((item) => {
                      //     return item.includes('@');
                      //   }),
                      // ]);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer2>
                <CustomFormItemPer2
                  label='Asset'
                  name='assetId'
                  rules={generateFormRules('Asset', ['required'])}
                >
                  <Select
                    disabled={companyId ? false : true}
                    showSearch
                    placeholder='Choose asset'
                    style={{ width: '100%' }}
                    options={assetMapped}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer2>
                <CustomFormItemPer1
                  label='Email Template'
                  name='templateId'
                  rules={generateFormRules('Email Template', ['required'])}
                >
                  <Select
                    disabled={companyId ? false : true}
                    showSearch
                    placeholder='Choose template'
                    style={{ width: '100%' }}
                    options={templateEmailMapped}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer1>

                <CustomFormItemPer1
                  label='Status'
                  name='status'
                  valuePropName='checked'
                  initialValue={true}
                >
                  <Switch defaultChecked className='custom-switch' />
                </CustomFormItemPer1>
              </Row>
            </SectionContent>

            <Divider />

            <SectionContent
              groupTitle={`Scheduler Delivery Configuration`}
              subTitle='These are email targets information, you can change anything'
            >
              <Row>
                <CustomFormItemPer2
                  label='Frequency'
                  name='frequency'
                  rules={generateFormRules('Frequency', ['required'])}
                >
                  <Select
                    showSearch
                    placeholder='Choose frequency'
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      setFrequency(e);
                      form.setFieldValue('sendingAt', undefined);
                    }}
                    options={[
                      {
                        value: 'DAILY',
                        label: 'DAILY',
                      },
                      // {
                      //   value: 'WEEKLY',
                      //   label: 'WEEKLY',
                      // },
                      // {
                      //   value: 'MONTHLY',
                      //   label: 'MONTHLY',
                      // },
                    ]}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer2>
                {frequency === 'DAILY' ? (
                  <CustomFormItemPer2
                    label='Sending Time'
                    name='sendingAt'
                    rules={generateFormRules('Sending Time', ['required'])}
                  >
                    <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                  </CustomFormItemPer2>
                ) : (
                  <CustomFormItemPer2
                    label='Sending Date'
                    name='sendingAt'
                    rules={generateFormRules('Sending Time', ['required'])}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      showTime
                      format='YYYY-MM-DD HH:mm'
                      disabledDate={(current) =>
                        frequency === 'DAILY' &&
                        (current.isBefore(new Date(), 'day') ||
                          current.isAfter(new Date(), 'day'))
                      }
                    />
                  </CustomFormItemPer2>
                )}

                <CustomFormItemPer2
                  label='Data Time From'
                  name='timeFrom'
                  rules={generateFormRules('Date Time From', ['required'])}
                >
                  <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                </CustomFormItemPer2>
                <CustomFormItemPer2
                  label='Data Time To'
                  name='timeTo'
                  rules={generateFormRules('Data Time To', ['required'])}
                >
                  <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                </CustomFormItemPer2>
                {/* <CustomFormItemPer2 label="Day of Week" name="dayOfWeek">
                <Input placeholder="Input day of week" />
              </CustomFormItemPer2>
              <CustomFormItemPer2 label="Date of Month" name="dateOfMonth">
                <Input placeholder="Input date of month" />
              </CustomFormItemPer2> */}
                {/* <CustomFormItemPer2
                label='Data Threshold Min'
                name='dataThresholdMin'
                rules={generateFormRules('Asset', ['numeric'])}
              >
                <Tooltip title='Percentage of the minimum threshold of data that the system will use to determine whether or not to send a report.'>
                  <Input
                    type='number'
                    max={100}
                    min={0}
                    placeholder='Input data threshold min'
                    suffix='%'
                  />
                </Tooltip>
              </CustomFormItemPer2> */}
              </Row>
            </SectionContent>

            <Divider />

            <SectionContent
              groupTitle={`Email Targets`}
              subTitle='Choose target user for this schedule email. If you want to add email as receiver then tick the checkbox beside user name, '
            >
              {/* <CustomFormItemPer1
              label='Add Email'
              name='addEmail'
              rules={generateFormRules('Email', ['email'])}
            >
              <Row gutter={15}>
                <Col span={20}>
                  <Input
                    value={email}
                    placeholder='Input data email'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type='primary'
                    onClick={() => {
                      setUsersOrEmails((prev) => [
                        { userId: null, email },
                        ...prev,
                      ]);
                      setEmail('');
                    }}
                  >
                    Add Email
                  </Button>
                </Col>
              </Row>
            </CustomFormItemPer1> */}
              <CustomFormItemPer1>
                <Table<ITargetEmail>
                  columns={columnsEmail}
                  dataSource={usersOrEmails}
                  rowSelection={{ type: selectionType, ...rowSelection }}
                  pagination={false}
                />
              </CustomFormItemPer1>
            </SectionContent>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default MasterReportEmailTargetAssetEdit;
