import {
  BaseResponseProps,
  generateFormRules,
  getStandardError,
} from '@qlibs/react-components';
import { Button, Form, Input, Row, Switch, message } from 'antd';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from '../../user/components/CustomFormItem';
import { httpRequest } from '../../../helpers/api';
import {
  ICreateOperationModeDetail,
  OperationModeDetailProperties,
} from '../types/operationMode.type';
import React from 'react';

interface IProps {
  operationModeId: string;
  onClose: () => void;
}

const OperationModeDetailCreate: React.FC<IProps> = ({
  operationModeId,
  onClose,
}) => {
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();

  async function createOperationModeDetail(values: ICreateOperationModeDetail) {
    try {
      setIsLoadingAction(true);
      const formData = {
        operationModeId,
        name: values.name,
        rpmMax: values.rpmMax,
        consumptionRate: values.consumptionRate,
        status: values.status ? 'active' : 'inactive',
      };

      console.log(formData);

      await httpRequest.post<BaseResponseProps<OperationModeDetailProperties>>(
        '/operation-mode-detail',
        formData
      );

      message.success(`Success add operation mode detail`);
      form.resetFields();
      onClose();
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createOperationModeDetail(values);
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        name='profileForm'
        layout='vertical'
        onFinish={handleSubmit}
        autoComplete='off'
      >
        <CustomFormItemPer1
          label='Name'
          name='name'
          rules={generateFormRules('Name', ['required'])}
        >
          <Input placeholder='Input name' />
        </CustomFormItemPer1>
        <CustomFormItemPer1
          label='RPM Max'
          name='rpmMax'
          rules={generateFormRules('RPM Max', ['numeric', 'required'])}
        >
          <Input placeholder='Input rpm max' type='number' />
        </CustomFormItemPer1>
        <CustomFormItemPer1
          label='Consumption Rate'
          name='consumptionRate'
          rules={generateFormRules('Consumption Rate', ['required'])}
        >
          <Input placeholder='Input consumption rate' suffix='L/m' />
        </CustomFormItemPer1>

        <CustomFormItemPer1
          label='Status'
          name='status'
          valuePropName='checked'
          initialValue={true}
        >
          <Switch defaultChecked className='custom-switch' />
        </CustomFormItemPer1>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button loading={isLoadingAction} type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default OperationModeDetailCreate;
