import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from '@qlibs/react-components';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from '../../user/components/CustomFormItem';
import { httpRequest } from '../../../helpers/api';
import SectionContent from '../../../components/SectionContent';
import { AssetProperties } from '../../asset/types/asset.type';
import {
  ICreateOperationModeDetail,
  OperationModeDetailProperties,
} from '../types/operationMode.type';
import dayjs from 'dayjs';
import useMasterData from '../../../hooks/useMasterData';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';

interface IProps {
  operationModeId: string;
  operationModeDetailId: string;
  onClose: () => void;
}

const OperationModeDetailEdit: React.FC<IProps> = ({
  operationModeId,
  operationModeDetailId,
  onClose,
}) => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<OperationModeDetailProperties>();
  const [form] = Form.useForm();

  async function updateOperationMode(values: ICreateOperationModeDetail) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        rpmMax: values.rpmMax,
        consumptionRate: values.consumptionRate,
        status: values.status ? 'active' : 'inactive',
      };

      console.log(formData);
      // return;

      await httpRequest.patch<BaseResponseProps<OperationModeDetailProperties>>(
        '/operation-mode-detail/' + operationModeDetailId,
        formData
      );

      message.success(`Success update operation mode detail`);
      form.resetFields();
      setData(undefined);
      navigate(`/operation-mode/${operationModeId}`);
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning('This data has been archived and cannot be edited');
    } else {
      updateOperationMode(values);
    }
  };

  async function fetchData(operationModeDetailId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<OperationModeDetailProperties>
      >('/operation-mode-detail/' + operationModeDetailId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          status: data.status === 'active' ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get operation mode detail data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (operationModeDetailId) {
      fetchData(operationModeDetailId);
    }
  }, [operationModeDetailId]);

  return (
    <React.Fragment>
      {data?.deletedAt ? (
        <Alert
          className='mb-5'
          type='warning'
          message='This data has been archived and cannot be edited'
        />
      ) : (
        false
      )}
      <Form
        form={form}
        name='profileForm'
        layout='vertical'
        onFinish={handleSubmit}
        autoComplete='off'
      >
        <CustomFormItemPer1
          label='Name'
          name='name'
          rules={generateFormRules('Name', ['required'])}
        >
          <Input placeholder='Input name' />
        </CustomFormItemPer1>
        <CustomFormItemPer1
          label='RPM Max'
          name='rpmMax'
          rules={generateFormRules('RPM Max', ['numeric', 'required'])}
        >
          <Input placeholder='Input rpm max' type='number' />
        </CustomFormItemPer1>
        <CustomFormItemPer1
          label='Consumption Rate'
          name='consumptionRate'
          rules={generateFormRules('Consumption Rate', ['required'])}
        >
          <Input placeholder='Input consumption rate' suffix='L/m' />
        </CustomFormItemPer1>

        <CustomFormItemPer1
          label='Status'
          name='status'
          valuePropName='checked'
          initialValue={true}
        >
          <Switch defaultChecked className='custom-switch' />
        </CustomFormItemPer1>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button loading={isLoadingAction} type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default OperationModeDetailEdit;
