import React, { Suspense, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Layout,
  Modal,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import {
  DownOutlined,
  LeftCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import useConfigApp from '../../hooks/useConfigApp';
import AppLogo from '../../components/AppLogo';
import Breadcrumbs from '../../components/Breadcrumbs';
import Sidebar from '../../components/Sidebar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../../assets/theme';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { httpRequest } from '../../helpers/api';
import {
  MainCheckHealth,
  IdleHandler,
  thisYear,
  IHttpResponse,
} from '@qlibs/react-components';
import { MENUS_FLAT } from '../../const/menu';
import config, { APP_VERSION } from '../../const/config';
import { isMobile } from 'react-device-detect';
import useGroup from '../../zustand/useGroup';
import ActionApplication from '../../components/ActionApplication';
import useMasterData from '../../hooks/useMasterData';

const { Header, Sider, Content } = Layout;

const AppLayout = () => {
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const location = useLocation();
  const auth = useAuthUser();
  const [sidebarShow, setSidebarShow] = useState(isMobile ? false : true);
  const pathname = location.pathname;

  return (
    <>
      <div>
        {/* {!!process.env.REACT_APP_CHECK_HEALTH_URL && (
          <MainCheckHealth
            url={process.env.REACT_APP_CHECK_HEALTH_URL || '/'}
            axiosInstance={httpRequest as any}
          />
        )} */}
        {/* <IdleHandler
          pingUrl={process.env.REACT_APP_CHECK_HEALTH_URL || '/'}
          axiosInstance={httpRequest as any}
          isLoggedIn={auth() ? true : false}
          idleTimeout={undefined}
          sessionTimeout={undefined}
          callbackOnError={() => {
            signOut();
            navigate('/login');
          }}
        >
          {' '}
        </IdleHandler> */}
      </div>
      <Layout
        className='theme-2'
        style={{ height: '100vh', flexDirection: 'row' }}
      >
        {sidebarShow && (
          <AppSider
            onCollapse={toggleSidebar}
            trigger={null}
            collapsible
            collapsed={sidebarCollapsed}
            collapsedWidth={70}
            width={240}
            style={{
              paddingLeft: 0,
              height: '100vh',
            }}
          >
            <AppLogo color='color' collapsed={sidebarCollapsed} />
            <Sidebar collapsed={sidebarCollapsed} />
          </AppSider>
        )}
        {sidebarShow && sidebarCollapsed ? (
          <Tooltip placement='right' title={'Expand Sidebar'}>
            <Button
              style={{
                position: 'absolute',
                bottom: 0,
                height: 40,
                width: 70,
                backgroundColor: theme.colors.secondary,
              }}
              onClick={toggleSidebar}
              type='text'
              icon={
                <RightCircleOutlined
                  style={{ color: theme.colors.white }}
                  className='icon-collapsed'
                />
              }
            />
          </Tooltip>
        ) : sidebarShow ? (
          <MinimizeButton
            onClick={() => {
              if (!isMobile) {
                toggleSidebar();
              }
            }}
            className='minimize-container'
          >
            <div>
              v{APP_VERSION} · ©{thisYear}
            </div>
            {!isMobile && <LeftCircleOutlined />}
          </MinimizeButton>
        ) : (
          false
        )}

        <Layout
          id='scroll-content'
          className='site-layout'
          style={{ minHeight: '100vh', overflowY: 'scroll' }}
        >
          <AppHeader>
            {isMobile && sidebarShow ? (
              <span
                style={{ cursor: 'pointer', padding: 5 }}
                onClick={() => setSidebarShow(false)}
              >
                <MenuFoldOutlined style={{ fontSize: 20 }} />
              </span>
            ) : isMobile ? (
              <span
                style={{ cursor: 'pointer', padding: 5 }}
                onClick={() => setSidebarShow(true)}
              >
                <MenuOutlined style={{ fontSize: 20 }} />
              </span>
            ) : (
              false
            )}
            {config.pageTitlePosition === 'top-nav' &&
              !isMobile &&
              MENUS_FLAT.map((item: any) => {
                if (item.key === location.pathname) {
                  return (
                    <>
                      <AppHeaderLabel>
                        {item.icon}
                        <Typography.Title
                          level={3}
                          style={{ marginBottom: 0, fontSize: 18 }}
                        >
                          {item.label} dsa
                        </Typography.Title>
                        <Divider type='vertical' />
                        <Typography.Text style={{ color: 'darkgrey' }}>
                          {item.description}
                        </Typography.Text>
                      </AppHeaderLabel>
                    </>
                  );
                } else {
                  return false;
                }
              })}

            {config.pageTitlePosition === 'content' && !isMobile && (
              <Breadcrumbs />
            )}

            <AppHeaderAccount>
              {config.topNav.showWelcomeName && (
                <DateNow>
                  Welcome, <b>{auth()?.firstName || auth()?.name}</b>
                  <small>
                    (<i>{auth()?.role.roleName}</i>)
                  </small>
                </DateNow>
              )}
              {!isMobile && <DividerTitle />}
              <DividerTitle />
              <ActionApplication />
              <DividerTitle />
              <UserAvatar />
            </AppHeaderAccount>
          </AppHeader>

          <AppContent pathname={pathname}>
            <Space size='middle' direction='vertical'>
              {config.pageTitlePosition === 'top-nav' && (
                <Breadcrumbs marginTop={10} />
              )}

              <Suspense fallback={<Spin spinning={true} />}>
                <Outlet />
              </Suspense>
            </Space>

            {pathname !== '/home' && <div style={{ height: 100 }}></div>}
          </AppContent>
        </Layout>

        {/* <TawkMessengerReact
          propertyId="65a62d590ff6374032c0c3ea"
          widgetId="1hk8i29gf"
        /> */}
      </Layout>
    </>
  );
};

const AppContent = styled(Content)<{ pathname: string }>`
  height: 100%;
  padding: ${(props) => (props.pathname === '/home' ? '0 !important' : '10px')};
  padding-right: ${(props) =>
    props.pathname === '/home' ? '0 !important' : '5px'};
  overflow: ${(props) => (props.pathname === '/home' ? 'hidden' : 'scroll')};
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  padding-left: 10px;
`;

const AppHeader = styled(Header)`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding: 0 20px;
  height: 60px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 20px;
  grid-template-columns: ${isMobile ? undefined : '1fr auto auto auto auto'};
  align-items: center;
  justify-content: flex-end;
`;

const AppHeaderLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DividerTitle = styled.div`
  width: 1px;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.05);
`;

const MinimizeButton = styled.div``;

export default AppLayout;
