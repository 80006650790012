import { useNavigate } from 'react-router-dom';
import usePermission from '../../hooks/usePermission';
import React, { useEffect } from 'react';
import {
  HeaderSection,
  AppTable,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  IAppTableColumn,
  BaseResponsePaginationProps,
} from '@qlibs/react-components';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { Alert, Button, Col, Image, Modal, Tag, message } from 'antd';
import { httpRequest } from '../../helpers/api';
import { EDateFormat } from '../../const/config';
import { AssetProperties } from '../asset/types/asset.type';
import LinkTable from '../../components/LinkTable';
import { OperationModeProperties } from './types/operationMode.type';
import useMasterData from '../../hooks/useMasterData';
import useGroup from '../../zustand/useGroup';
import OperationModeDetail from './operationModeDetail';
const moment = require('moment');

const FEATURE = 'OPERATION_MODE';

const OperationMode = () => {
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();
  const { getListCompanies, companies } = useMasterData();

  const handleCreateDevice = () => {
    navigate({ pathname: '/operation-mode/add' });
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = React.useState(false);
  const [currentData, setCurrentData] =
    React.useState<OperationModeProperties>();
  const [forceDeleteId, setForceDeleteId] = React.useState<string>();
  const [detailModal, setModalDetail] = React.useState<boolean>(false);
  const [activeOperationMode, setActiveOperationMode] =
    React.useState<OperationModeProperties>();

  const { company } = useGroup();

  const {
    isLoading,
    data,
    pagination,
    query,
    setQuery,
    changePage,
    changeLimit,
    pageQueries,
    filterDropdown,
  } = useFetchList<OperationModeProperties>({
    httpRequest: httpRequest as any,
    endpoint: 'operation-mode',
    limit: +PAGE_SIZE_OPTIONS[1],
    initialQuery: {
      filterCompanyIds: company?.companyId || '',
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  async function deleteData() {
    try {
      if (currentData) {
        const forceDelete =
          forceDeleteId && forceDeleteId === currentData.operationModeId;
        await httpRequest.delete(
          '/operation-mode/' + currentData.operationModeId,
          {
            params: {
              forceDelete: forceDelete || undefined,
            },
          }
        );
        message.success(
          `Success ${forceDelete ? 'delete' : 'archive'} operation mode`
        );

        setQuery({
          ...query,
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalDeleteOpen(false);
      setCurrentData(undefined);
    }
  }

  async function restoreData() {
    try {
      if (currentData) {
        await httpRequest.patch(
          '/operation-mode/' + currentData.operationModeId + '/restore'
        );
        message.success(`Success restore operation mode`);

        setQuery({
          ...query,
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalRestoreOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function handleRestoreDevice(id: any) {
    setIsModalRestoreOpen(true);
    const found = data.find((item) => item.operationModeId === id);
    if (found) {
      setCurrentData(found);
    }
  }

  async function handleDeleteDevice(id: any, forced?: boolean) {
    setIsModalDeleteOpen(true);
    const found = data.find((item) => item.operationModeId === id);
    if (found) {
      setCurrentData(found);
    }

    if (forced) {
      setForceDeleteId(id);
    }
  }

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
    setIsModalRestoreOpen(false);
    setCurrentData(undefined);
  }

  function isArchived(id?: string) {
    if (id) {
      const found = data.find(
        (item) => item.operationModeId === id && item.deletedAt
      );
      if (found) {
        return true;
      }
    }

    return false;
  }

  const filterCompanyOptions = React.useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [{ value: '', label: 'All' }, ...data];
  }, [companies]);

  const columns: IAppTableColumn<OperationModeProperties>[] = [
    {
      title: 'ASSET',
      dataIndex: 'asset',
      key: 'asset',
      type: 'detail',
      keyId: 'operationModeId',
      render: (value, records) => (
        <LinkTable
          onPress={() => {
            setModalDetail(true);
            setActiveOperationMode(records);
          }}
        >
          {records.deletedAt ? (
            <Tag color='#ff5500' className='mb-2'>
              Archived
            </Tag>
          ) : (
            false
          )}
          {records?.asset?.name}
        </LinkTable>
      ),
    },
    {
      title: 'COMPANY',
      dataIndex: 'company',
      key: 'company',
      render: (value, records) => <div>{records?.company?.name}</div>,
    },
    {
      title: 'DEVICE',
      dataIndex: 'uniqueId',
      key: 'uniqueId',
      render: (value, records) => (
        <div>
          <div>{records?.device?.name}</div>
          <div style={{ fontSize: 12, opacity: 0.6 }}>
            Device ID: {records?.device?.uniqueId}
          </div>
        </div>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (value, _) => (
        <Tag color={value === 'active' ? 'success' : 'error'}>
          {value.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'START AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, records) =>
        moment(records.startAt).format('DD MMMM YYYY'),
    },
    {
      title: 'ACTION',
      key: 'action',
      type: 'actions',
      actions: [
        isUserHasPermission([FEATURE + '.DETAIL'])
          ? {
              key: 'detail',
              label: 'Detail',
              onPress: (id) => navigate('/operation-mode/' + id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.UPDATE'])
          ? {
              key: 'update',
              label: 'Update',
              onPress: (id) => navigate('/operation-mode/' + id + '/edit'),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.DELETE'])
          ? {
              key: 'archive',
              label: 'Delete',
              color: '#ff4d4f',
              disabled: (id) => isArchived(id),
              disabledTooltip: 'This data is already archived',
              onPress: (id) => handleDeleteDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.RESTORE'])
          ? {
              key: 'restore',
              label: 'Restore',
              color: '#1890ff',
              disabled: (id) => !isArchived(id),
              disabledTooltip: 'This data not in archived',
              onPress: (id) => handleRestoreDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.FORCE_DELETE'])
          ? {
              key: 'delete',
              label: 'Force Delete',
              onPress: (id) => handleDeleteDevice(id, true),
            }
          : undefined,
      ],
    },
  ];

  const filterData: any = [
    {
      type: 'select',
      key: 'company',
      label: 'Company',
      options: filterCompanyOptions,
      value: pageQueries.filterCompanyIds || 'All',
      onChange: (value: any) => {
        filterDropdown({
          filterCompanyIds: value === 'all' ? '' : value,
        });
      },
      colSpan: 8,
    },
    {
      type: 'select',
      key: 'status',
      label: 'Status',
      options: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterStatus: value === 'all' ? '' : value,
        });
      },
      colSpan: 6,
    },
    {
      type: 'select',
      key: 'filterData',
      label: 'Filter Data',
      options: [
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'show',
          label: 'Include Archive Data',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterArchived: value === 'default' ? '' : value,
        });
      },
      colSpan: 4,
    },
  ].filter((item) =>
    !isUserHasPermission([FEATURE + '.DELETE'])
      ? item.key !== 'filterData'
      : item
  );

  useEffect(() => {
    getListCompanies();
  }, []);

  return (
    <React.Fragment>
      <HeaderSection
        title={'Operation Mode'}
        rightAction={[
          isUserHasPermission([FEATURE + '.CREATE']) ? (
            <Button type='primary' onClick={handleCreateDevice}>
              Add Operation Mode
            </Button>
          ) : (
            false
          ),
        ]}
      />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || 'all',
          filterData: pageQueries.filterData || 'default',
          company: company.name || 'all',
        }}
        filters={[filterData]}
      />

      <AppTable
        isLoading={isLoading}
        keyId='operationModeId'
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />

      {activeOperationMode && (
        <Modal
          title={`Operation Mode ${activeOperationMode.company?.name}`}
          open={detailModal}
          width={900}
          onCancel={() => {
            setModalDetail(false);
            setActiveOperationMode(undefined);
          }}
          footer={''}
        >
          <Alert
            style={{ marginBottom: 10, marginTop: 10 }}
            message={
              <div style={{ display: 'flex', gap: 20 }}>
                <div>
                  <b>Device Name:</b> {activeOperationMode.device?.name || '-'}
                </div>
                <div>
                  <b>Device ID:</b>{' '}
                  {activeOperationMode.device?.uniqueId || '-'}
                </div>
              </div>
            }
            type='warning'
          />
          <OperationModeDetail
            operationModeId={activeOperationMode.operationModeId}
          />
        </Modal>
      )}

      <Modal
        title={
          forceDeleteId ? 'Force Delete Confirmation' : 'Delete Confirmation'
        }
        open={isModalDeleteOpen}
        onOk={deleteData}
        okButtonProps={{
          style: {
            background: 'red',
          },
        }}
        onCancel={handleCancelDelete}
      >
        {forceDeleteId ? (
          <p>
            Are you sure want to permanently delete this data? This data will be
            deleted and only can't be undo.
          </p>
        ) : (
          <p>
            Are you sure want to delete this data? This data will be archived
            and only User with restore permission that can undo this action.
          </p>
        )}
      </Modal>

      <Modal
        title='Restore Confirmation'
        open={isModalRestoreOpen}
        onOk={restoreData}
        okButtonProps={{
          style: {
            background: 'blue',
          },
        }}
        onCancel={handleCancelDelete}
      >
        <p>Are you sure want to restore this data?.</p>
      </Modal>
    </React.Fragment>
  );
};
export default OperationMode;
