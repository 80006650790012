export const distanceUnitOptions = [
  { value: 'M', label: 'M' },
  { value: 'KM', label: 'KM' },
  { value: 'NM', label: 'NM' },
];

export const cruiseUnitOptions = [
  { value: 'KM', label: 'KM' },
  { value: 'NM', label: 'NM' },
  { value: 'MILES', label: 'MILES' },
];

export const speedUnitOptions = [
  { value: 'KM/H', label: 'KM/H' },
  { value: 'KNOT', label: 'KNOT' },
];

export const literUnitOptions = [
  { value: 'Liter/H', label: 'Liter/H' },
  { value: 'Liter/Min', label: 'Liter/Min' },
];
