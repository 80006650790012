import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import usePermission from '../hooks/usePermission';
import { MENUS } from '../const/menu';
import styled from 'styled-components';

const Sidebar: React.FC<{ collapsed: boolean }> = ({
  collapsed: sidebarCollapsed,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();

  const filterMenuOnlyHasPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item.permission && Array.isArray(item.permission)) {
          item.permission = isUserHasPermission(item.permission);
        }

        if (item.children) {
          item.children = filterMenuOnlyHasPermission(item.children);

          if (item.children.length === 0) {
            item.permission = false;
          } else {
            item.permission = true;
          }
        }

        if (item.permission) {
          return item;
        } else {
          return null;
        }
      })
      .filter((item) => item);
  };

  const items = filterMenuOnlyHasPermission(MENUS) as MenuProps['items'];
  const convertPathName = () => {
    const pathname = location.pathname.split('/');
    const res = '/' + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && 'children' in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : '');
            if (submenu && 'children' in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  'path' in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <MenuCustom
      collapsed={sidebarCollapsed}
      className={sidebarCollapsed ? 'menu-collapsed' : ''}
      mode='inline'
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      items={items}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};

const MenuCustom = styled(Menu)<{ collapsed?: boolean }>`
  ${(props) =>
    props.collapsed &&
    `
    .ant-menu-submenu {
      padding-left: 4px !important;
      color: white !important;
    }
    `}
`;
export default Sidebar;
