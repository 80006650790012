import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from '@qlibs/react-components';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from '../user/components/CustomFormItem';
import useAddress from '../../hooks/useAddress';
import { httpRequest, httpRequestMultipart } from '../../helpers/api';
import SectionContent from '../../components/SectionContent';
import { AssetProperties } from '../asset/types/asset.type';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import {
  ICreateOperationMode,
  OperationModeProperties,
} from './types/operationMode.type';
import dayjs from 'dayjs';
import useMasterData from '../../hooks/useMasterData';

const OperationModeCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();

  const [groupId, setGroupId] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [assetId, setAssetId] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  const {
    loadingAsset,
    loadingCompany,
    loadingDevice,
    loadingGroup,
    getListGroup,
    getListCompanies,
    getListAssets,
    getListDevices,
    groups,
    companies,
    assets,
    devices,
  } = useMasterData();

  async function createOperationMode(values: ICreateOperationMode) {
    try {
      setIsLoadingAction(true);
      const formData = {
        groupId: values.groupId,
        companyId: values.companyId,
        assetId: values.assetId,
        deviceId: values.deviceId,
        startAt: dayjs(values.startAt),
        status: values.status ? 'active' : 'inactive',
      };

      console.log(formData);
      // return;

      await httpRequest.post<BaseResponseProps<OperationModeProperties>>(
        '/operation-mode',
        formData
      );

      message.success(`Success add operation mode`);
      form.resetFields();
      navigate('/operation-mode');
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createOperationMode(values);
  };

  useEffect(() => {
    getListGroup();
  }, []);

  useEffect(() => {
    if (groupId)
      getListCompanies({
        filterGroupIds: groupId,
      });
  }, [groupId]);

  useEffect(() => {
    if (companyId)
      getListAssets({
        filterCompanyIds: companyId,
      });
  }, [companyId]);

  useEffect(() => {
    if (assetId)
      getListDevices({
        filterAssetIds: assetId,
      });
  }, [assetId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={'Add Operation Mode'}
        subtitle={'Create new operation mode'}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Information'
            subTitle='Detail information about operation mode'
          >
            <Row>
              <CustomFormItemPer2
                label='Group'
                name='groupId'
                rules={generateFormRules('Group', ['required'])}
              >
                <Select
                  loading={loadingGroup}
                  disabled={loadingGroup}
                  showSearch
                  placeholder='Search group'
                  style={{ width: '100%' }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setGroupId(value);

                    setCompanyId('');
                    setAssetId('');
                    setDeviceId('');

                    form.setFieldValue('companyId', null);
                    form.setFieldValue('assetId', null);
                    form.setFieldValue('deviceId', null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={groups.map((item) => ({
                    value: item.groupId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label='Company'
                name='companyId'
                rules={generateFormRules('Company', ['required'])}
              >
                <Select
                  loading={loadingCompany}
                  showSearch
                  disabled={groupId ? false : true}
                  placeholder='Search company'
                  style={{ width: '100%' }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setCompanyId(value);

                    setAssetId('');
                    setDeviceId('');

                    form.setFieldValue('assetId', null);
                    form.setFieldValue('deviceId', null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={companies.map((item) => ({
                    value: item.companyId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label='Asset'
                name='assetId'
                rules={generateFormRules('Asset', ['required'])}
              >
                <Select
                  loading={loadingAsset}
                  showSearch
                  disabled={companyId ? false : true}
                  placeholder='Search asset'
                  style={{ width: '100%' }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setAssetId(value);

                    setDeviceId('');

                    form.setFieldValue('deviceId', null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={assets.map((item) => ({
                    value: item.assetId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label='Device Unique ID'
                name='deviceId'
                rules={generateFormRules('Device Unique ID', ['required'])}
              >
                <Select
                  loading={loadingDevice}
                  showSearch
                  disabled={assetId ? false : true}
                  placeholder='Search device unique id'
                  style={{ width: '100%' }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => setDeviceId(value)}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={devices.map((item) => ({
                    value: item.uniqueId,
                    label: `${item.name} (${item.uniqueId})`,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2 label='Start Date' name='startAt'>
                <DatePicker style={{ width: '100%' }} />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle='These are operation mode information, you can change anything'
          >
            <CustomFormItemPer1
              label='Status'
              name='status'
              valuePropName='checked'
              initialValue={true}
            >
              <Switch defaultChecked className='custom-switch' />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default OperationModeCreate;
