import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import {
  EReportDataInterval,
  ILogReport,
  ILogReportFilter,
} from '../types/report-log.type';
import {
  convertIntervalToSecond,
  fillNullValues,
  generateDataSeries,
  generateSeriesDataZone,
  generateTimestampData,
  hChartConfig,
  toHHMMSS,
} from './helperFunction';
import dayjs from 'dayjs';

const windowHeight = window.innerHeight;

const defaultYAxis = [
  {
    key: 'rpm',
    min: 0,
    color: 'rgb(0, 0, 0)',
    text: 'RPM',
  },
  {
    key: 'fuel',
    min: 0,
    color: 'rgb(255, 162, 22)',
    text: 'Liters',
  },
  {
    key: 'speed',
    min: 0,
    color: 'rgb(243, 69, 132)',
    text: 'Knot',
    opposite: true,
  },
  {
    key: 'time',
    min: 0,
    color: 'rgb(0, 0, 0)',
    text: 'Minute',
    fixed: true,
    opposite: true,
  },
];

export const zoneChartType = ['line', 'spline', 'areaspline', 'arealine'];

interface IProps {
  logs: ILogReport;
  filter: ILogReportFilter;
  yAxis: string[];
  chartSchema: {
    logKey: string;
    dataKeys: string[];
    seriesOption: {
      name: string;
      type: string;
      yAxis: number;
      zIndex: number;
      marker: {
        enabled: boolean;
        fillColor: string;
      };
      lineColor: string;
      fillColor?: string;
      tooltip?: {
        valueSuffix: string;
      };
    };
  }[];
  isReportSchedule?: boolean;
}

const ReportChart: React.FC<IProps> = ({
  logs,
  filter,
  yAxis,
  chartSchema,
  isReportSchedule,
}) => {
  const [dataChart, setDataChart] = useState<any>();

  const generateYAxis = (
    data: {
      color: string;
      text: string;
      opposite?: boolean;
      fixed?: boolean;
    }[]
  ) => {
    const yAxis: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> = [];
    for (let idx = 0; idx < data.length; idx++) {
      const item = data[idx];
      yAxis.push({
        labels: {
          format: '{value}',
          style: {
            color: item.color,
          },
          formatter(
            this: Highcharts.AxisLabelsFormatterContextObject,
            ctx: Highcharts.AxisLabelsFormatterContextObject
          ) {
            if (item.fixed) {
              return Number(this.value).toFixed(2);
            }
            return this.value.toString();
          },
        },
        title: {
          text: item.text === 'Nautical Miles' ? 'Hour' : item.text,
          style: {
            color:
              item.text === 'Nautical Miles' ? 'rgb(111, 87, 233)' : item.color,
          },
        },
        lineColor:
          item.color === '#000000'
            ? 'rgba(0, 0, 0, 0.1)'
            : item.text === 'Nautical Miles'
            ? 'rgb(111, 87, 233)'
            : item.color,
        lineWidth: 1,
        tickColor:
          item.color === '#000000'
            ? 'rgba(0, 0, 0, 0.1)'
            : item.text === 'Nautical Miles'
            ? 'rgb(111, 87, 233)'
            : item.color,
        tickWidth: 1,
        tickLength: 5,
        opposite: item.opposite,
      });
    }

    return yAxis;
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore && logs && Object.keys(logs).length) {
      let currChartOption: any = {
        ...hChartConfig,
        plotOptions: {
          series: {
            groupPadding: 0.1,
            pointPadding: 0,
            animation: false,
          },
        },
        yAxis: generateYAxis(
          defaultYAxis.filter((item) => yAxis.includes(item.key))
        ),
      };

      let intervalInSecond = convertIntervalToSecond(filter.interval);

      const timestamps = generateTimestampData({
        startTime: filter.startAt,
        endTime: filter.endAt,
        interval: filter.interval,
        intervalInSecond,
      });

      const dataSeries: any[] = [];

      chartSchema.forEach((schema) => {
        if ((logs as any)[schema.logKey]) {
          const res = generateDataSeries({
            timestamps,
            key: schema.dataKeys,
            fm: (logs as any)[schema.logKey],
            seriesOption: schema.seriesOption,
          });

          dataSeries.push(res);
        }
      });

      currChartOption = {
        ...currChartOption,
        xAxis: [
          {
            categories: timestamps.map((item) =>
              dayjs(item * 1000).format('D/MM/YY HH:mm')
            ),
            crosshair: true,
          },
        ],
        tooltip: {
          shared: true,
          formatter: function (
            this: Highcharts.TooltipFormatterContextObject
          ): string {
            let tooltip = `<strong>${this.x}</strong><br>`;

            this.points?.forEach((point) => {
              if (point.series.name === 'AE Running Time') {
                tooltip += `${point.series.name}: <strong>${toHHMMSS(
                  point.y
                )}</strong><br>`;
              } else {
                tooltip += `${point.series.name}: <strong>${point.y}</strong><br>`;
              }
            });

            return tooltip;
          },
        },
        series: dataSeries.map((serie: any) => {
          const data = [...serie.data].map((value: number | undefined) => {
            return typeof value === 'undefined'
              ? null
              : (value && typeof value === 'number') || value === 0
              ? Number(value.toFixed(2))
              : null;
          });

          let newData = data;

          if (zoneChartType.includes(serie.type)) {
            newData = fillNullValues(data);
          }

          return {
            ...serie,
            data: newData,
            zoneAxis: 'x',
            zones: zoneChartType.includes(serie.type)
              ? generateSeriesDataZone({
                  data,
                  color: serie.color,
                  type: serie.type,
                })
              : [],
          };
        }),
      };
      setDataChart(currChartOption);
    }

    return () => {
      ignore = true;
    };
  }, [logs]);

  return (
    <React.Fragment>
      <HighchartsReact
        containerProps={{
          style: {
            marginTop: 20,
            height: windowHeight - (isReportSchedule ? 250 : 150),
          },
        }}
        highcharts={Highcharts}
        options={{
          ...dataChart,
          chart: {
            zoomType: 'xy',
            events: {},
          },
        }}
        allowChartUpdate={true}
        immutable={true}
      />
    </React.Fragment>
  );
};

export default ReportChart;
