import {
  CompassOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  HistoryOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Card, Spin, Divider, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import styled from 'styled-components';
import {
  calculateGpsSummary,
  generateReportSummary,
  toHHMMSS,
} from '../helperFunction';
import useGroup from '../../../../zustand/useGroup';
import { EAssetType } from '../../../asset/types/asset.type';
import {
  cruiseUnitOptions,
  distanceUnitOptions,
  speedUnitOptions,
} from '../../../../helpers/unit';

interface IProps {
  defaultOpenBody?: string[];
  hideAccordion?: boolean;
}

const ReportSummary: React.FC<IProps> = (props) => {
  const {
    loadingLog: loading,
    logs,
    speedUnit,
    setSpeedUnit,
    distanceUnit,
    setDistanceUnit,
    cruiseUnit,
    setCruiseUnit,
  } = useReportLog();
  const { filter, asset } = useGroup();
  const [showBody, setShowBody] = useState<{
    body1: boolean;
    body2: boolean;
    body3: boolean;
    body4: boolean;
  }>({
    body1: false,
    body2: false,
    body3: false,
    body4: false,
  });

  function handleShowBody(key: any) {
    const newShowBody: any = { ...showBody };
    newShowBody[key] = !newShowBody[key];
    setShowBody((current) => {
      return {
        ...current,
        ...newShowBody,
      };
    });
  }

  useEffect(() => {
    if (props.defaultOpenBody && props.defaultOpenBody.length) {
      props.defaultOpenBody.forEach((key) => {
        const currentShowBody: any = { ...showBody };
        if (currentShowBody.hasOwnProperty(key)) {
          setShowBody((current) => {
            return {
              ...current,
              [key]: true,
            };
          });
        } else {
          setShowBody((current) => {
            return {
              ...current,
              [key]: false,
            };
          });
        }
      });
    }
  }, [props.defaultOpenBody]);

  const dataSource = useMemo(() => {
    return generateReportSummary({
      startAt: filter.startAt,
      endAt: filter.endAt,
      interval: filter.interval,
      logs,
    });
  }, [logs]);

  const gpsSummmary = useMemo(() => {
    return calculateGpsSummary(logs, { speedUnit, distanceUnit, cruiseUnit });
  }, [logs, speedUnit, distanceUnit, cruiseUnit]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <CardInfoContainer
          onClick={() => handleShowBody('body1')}
          style={{ cursor: 'pointer' }}
        >
          <CardInfoTitle style={{ marginTop: 15 }}>
            <CompassOutlined style={{ fontSize: 12, marginRight: 5 }} /> GPS
            Summary
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion>
              {showBody.body1 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion>
          )}
        </CardInfoContainer>
        {showBody.body1 && (
          <CardInfoContainer>
            <CardInfoWrapper>
              <CardInfoUnit size='small'>
                <div style={{ opacity: 0.7 }}>Total Cruise</div>
                <div>
                  {gpsSummmary.totalCruise}
                  <Select
                    size='small'
                    value={cruiseUnit}
                    style={{ width: 90 }}
                    onChange={(e) => setCruiseUnit(e)}
                    options={cruiseUnitOptions}
                  />
                </div>
              </CardInfoUnit>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfoUnit size='small'>
                <div style={{ opacity: 0.7 }}>Average Speed</div>
                <div>
                  {gpsSummmary.avgSpeed}{' '}
                  <Select
                    size='small'
                    value={speedUnit}
                    style={{ width: 90 }}
                    onChange={(e) => setSpeedUnit(e)}
                    options={speedUnitOptions}
                  />
                </div>
              </CardInfoUnit>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfoUnit size='small'>
                <div style={{ opacity: 0.7 }}>Distance</div>
                <div>
                  {gpsSummmary.totalDistance}
                  <Select
                    size='small'
                    value={distanceUnit}
                    style={{ width: 90 }}
                    onChange={(e) => setDistanceUnit(e)}
                    options={distanceUnitOptions}
                  />
                </div>
              </CardInfoUnit>
            </CardInfoWrapper>
          </CardInfoContainer>
        )}

        <Divider style={{ marginBottom: 0, marginTop: 15 }} />

        {![EAssetType.EMS].includes(asset.assetType) && (
          <React.Fragment>
            <CardInfoContainer
              style={{ cursor: 'pointer' }}
              onClick={() => handleShowBody('body2')}
            >
              <CardInfoTitle>
                <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />{' '}
                Total Fuel Consumption
              </CardInfoTitle>
              {!props.hideAccordion && (
                <Accordion>
                  {showBody.body2 ? <MinusOutlined /> : <PlusOutlined />}
                </Accordion>
              )}
            </CardInfoContainer>
            {showBody.body2 && (
              <CardInfoContainer>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>Engine Fuel Cons.</div>
                    <div>
                      {dataSource.totalEngineFuelCons.toFixed(2)}{' '}
                      <Unit>Liter</Unit>
                    </div>
                  </CardInfo>
                </CardInfoWrapper>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>AE Fuel Cons.</div>
                    <div>
                      {dataSource.totalAEFuelCons.toFixed(2)} <Unit>Liter</Unit>
                    </div>
                  </CardInfo>
                </CardInfoWrapper>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>Total Fuel Cons.</div>
                    <div>
                      {(
                        dataSource.totalEngineFuelCons +
                        dataSource.totalAEFuelCons
                      ).toFixed(2)}{' '}
                      <Unit>Liter</Unit>
                    </div>
                  </CardInfo>
                </CardInfoWrapper>
              </CardInfoContainer>
            )}
            <Divider style={{ marginBottom: 0, marginTop: 15 }} />
          </React.Fragment>
        )}

        <CardInfoContainer2
          style={{ cursor: 'pointer' }}
          onClick={() => handleShowBody('body3')}
        >
          <CardInfoTitle>
            <HistoryOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Running Time
          </CardInfoTitle>
          <CardInfoTitle>
            <GlobalOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Average RPM
          </CardInfoTitle>
          <CardInfoTitle>
            <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Average Flow
          </CardInfoTitle>
          <CardInfoTitle>
            <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Fuel Consumption
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion2>
              {showBody.body3 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion2>
          )}
        </CardInfoContainer2>
        {showBody.body3 && (
          <CardInfoContainer2>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>{toHHMMSS(dataSource.portRunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>{toHHMMSS(dataSource.starboardRunningHour)}</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>
                  {dataSource.portRPM.toFixed(2)} <Unit>RPM</Unit>
                </div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>
                  {dataSource.starboardRPM.toFixed(2)} <Unit>RPM</Unit>
                </div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>
                  {dataSource.portAverageFlow.toFixed(2)} <Unit>L/H</Unit>
                </div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>
                  {dataSource.starboardAverageFlow.toFixed(2)} <Unit>L/H</Unit>
                </div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>
                  {dataSource.portTotalFuel.toFixed(2)} <Unit>Liter</Unit>
                </div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>
                  {dataSource.starboardTotalFuel.toFixed(2)} <Unit>Liter</Unit>
                </div>
              </CardInfo>
            </CardInfoWrapper>
          </CardInfoContainer2>
        )}

        <Divider style={{ marginBottom: 0, marginTop: 15 }} />

        <CardInfoContainer2
          style={{ cursor: 'pointer' }}
          onClick={() => handleShowBody('body4')}
        >
          <CardInfoTitle>
            <HistoryOutlined style={{ fontSize: 12, marginRight: 5 }} />
            AE Running Time
          </CardInfoTitle>
          <CardInfoTitle>
            <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />
            AE Fuel Consumption
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion2>
              {showBody.body4 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion2>
          )}
        </CardInfoContainer2>
        {showBody.body4 && (
          <CardInfoContainer2>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>AE 1</div>
                <div>{toHHMMSS(dataSource.ae1RunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 2</div>
                <div>{toHHMMSS(dataSource.ae2RunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 3</div>
                <div>{toHHMMSS(dataSource.ae3RunningHour)}</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>AE 1</div>
                <div>
                  {dataSource.ae1TotalFuel.toFixed(2)} <Unit>Liter</Unit>
                </div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 2</div>
                <div>
                  {dataSource.ae2TotalFuel.toFixed(2)} <Unit>Liter</Unit>
                </div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 3</div>
                <div>
                  {dataSource.ae3TotalFuel.toFixed(2)} <Unit>Liter</Unit>
                </div>
              </CardInfo>
            </CardInfoWrapper>
          </CardInfoContainer2>
        )}
      </Spin>
    </React.Fragment>
  );
};

const Unit = styled.span`
  opacity: 0.7;
  font-weight: normal !important;
  font-size: 12px;
`;

const CardInfo = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px 15px;

    div {
      :first-child {
        opacity: 0.7;
      }
      :last-child {
        font-size: 16px;
        font-weight: bold;
      }
      span {
        color: black !important;
        opacity: 1 !important;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const CardInfoUnit = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px 15px;
    align-items: center;

    div {
      :last-child {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const Accordion = styled.div`
  margin-top: 15px;
  grid-column: 4;
`;

const Accordion2 = styled.div`
  margin-top: 15px;
  grid-column: 5;
`;

const CardInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: 20px;
`;

const CardInfoContainer2 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 40px;
  gap: 20px;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default ReportSummary;
