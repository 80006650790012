import {
  HomeOutlined,
  FileTextOutlined,
  TableOutlined,
  TagOutlined,
  PictureOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  BellOutlined,
  CalendarOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  PlayCircleOutlined,
  MonitorOutlined,
  FireOutlined,
  SafetyCertificateOutlined,
  ZhihuOutlined,
  WhatsAppOutlined,
  SelectOutlined,
  ToolOutlined,
  AppstoreOutlined,
  BankOutlined,
  MobileOutlined,
  DashboardOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { ReactComponent as IconBuilding } from '../assets/icons/icon-building.svg';

type IMenu = {
  type?: 'group' | 'submenu';
  key?: string;
  label: string;
  icon?: any;
  permission?: boolean | string[];
  description?: string;

  children?: IMenu[];
};
export const MENUS: IMenu[] = [
  {
    type: 'group',
    label: 'Monitoring',
    children: [
      {
        key: '/home',
        label: 'Home',
        icon: <HomeOutlined />,
        permission: ['HOME.LIST'],
      },
      {
        key: '/dashboard',
        label: 'Dashboard',
        icon: <DashboardOutlined />,
        permission: ['DASHBOARD.LIST'],
        description: 'See insight here',
      },
      {
        key: '/report-log',
        label: 'Report',
        icon: <FileTextOutlined />,
        permission: ['DATA_LOG.LIST'],
        description: 'See insight here',
        // children: [
        //   {
        //     permission: true,
        //     key: '/report-summary',
        //     label: 'Summary',
        //   },
        //   {
        //     permission: true,
        //     key: '/vessel-tracking',
        //     label: 'Vessel Tracking',
        //   },
        //   {
        //     permission: true,
        //     key: '/rpm-vs-fuel-cons-vs-speed-vs-ae',
        //     label: 'RPM Vs Fuel Cons. Vs Speed Vs AE Running Time',
        //   },
        //   {
        //     permission: true,
        //     key: '/me-fuel-consumption',
        //     label: 'ME Fuel Consumption',
        //   },
        //   {
        //     permission: true,
        //     key: '/port-fuel-used-vs-running-hour',
        //     label: 'PORT Fuel Used vs Running Hours',
        //   },
        //   {
        //     permission: true,
        //     key: '/stb-fuel-used-vs-running-hour',
        //     label: 'STARBOARD Fuel Used vs Running Hours',
        //   },
        //   {
        //     permission: true,
        //     key: '/ae-fuel-consumption',
        //     label: 'AE Fuel Consumption',
        //   },
        //   {
        //     permission: true,
        //     key: '/report-log',
        //     label: 'Data Log View',
        //   },
        // ],
      },
    ],
  },
  {
    type: 'group',
    label: 'Assets & Company',
    children: [
      {
        permission: ['GROUP.LIST'],
        key: '/group',
        label: 'Group',
        icon: <AppstoreOutlined />,
      },
      {
        permission: ['COMPANY.LIST'],
        key: '/company',
        label: 'Company',
        icon: <BankOutlined />,
      },
      {
        permission: ['ASSET.LIST'],
        key: '/asset',
        label: 'Asset',
        icon: <FileTextOutlined />,
      },
      {
        permission: ['DEVICE.LIST'],
        key: '/device',
        label: 'Device',
        icon: <MobileOutlined />,
      },
      {
        permission: ['DEVICE_TYPE.LIST'],
        key: '/device-type',
        label: 'Device Type',
        icon: <MobileOutlined />,
      },
    ],
  },
  {
    type: 'group',
    label: 'Options',
    children: [
      {
        permission: [
          'REPORT_EMAIL_RECEIVER.LIST',
          'REPORT_EMAIL_TARGET_ASSET.LIST',
        ],
        key: '/master-report',
        label: 'Master Report',
        icon: <MailOutlined />,
      },
      {
        permission: ['OPERATION_MODE.LIST'],
        key: '/operation-mode',
        label: 'Operation Mode',
        icon: <ToolOutlined />,
      },
    ],
  },
  {
    type: 'group',
    label: 'Users Config',
    children: [
      {
        permission: ['USER.LIST'],
        key: '/admin',
        label: 'Admin',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['ROLE.LIST'],
        key: '/role',
        label: 'Role & Permission',
        icon: <SafetyCertificateOutlined />,
      },
    ],
  },
];

export const getFlatMenus = (menus: IMenu[]) => {
  return menus
    .reduce((acc, curr) => {
      if (curr.children) {
        curr.children.forEach((child: IMenu) => {
          acc.push(child);

          if (child.children) {
            child.children.forEach((child2: IMenu) => {
              acc.push(child2);
            });
          }
        });
      }
      acc.push(curr);
      return acc;
    }, [] as any[])
    .filter((item: any) => item.key)
    .filter((item: any) => item.type !== 'group');
};

export const MENUS_FLAT = getFlatMenus(MENUS);
console.info('menus_flat', MENUS_FLAT);

export const getMenu = () => {
  return MENUS_FLAT.find(
    (item: any) => item.key === window?.location?.pathname
  );
};
