import { useNavigate, useParams } from 'react-router-dom';
import usePermission from '../../../hooks/usePermission';
import React, { startTransition, useEffect } from 'react';
import {
  HeaderSection,
  AppTable,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  IAppTableColumn,
  BaseResponsePaginationProps,
} from '@qlibs/react-components';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { Button, Image, Modal, Pagination, Tag, message } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { EDateFormat } from '../../../const/config';
import { AssetProperties } from '../../asset/types/asset.type';
import LinkTable from '../../../components/LinkTable';
import { OperationModeDetailProperties } from '../types/operationMode.type';
import useMasterData from '../../../hooks/useMasterData';
import useGroup from '../../../zustand/useGroup';
import {
  EFormatCurrentDate,
  formatCurrentDate,
} from '../../../helpers/formatCurrentDate';
import styled from 'styled-components';
import OperationModeDetailCreate from './Create';
import OperationModeDetailEdit from './Edit';
const moment = require('moment');

const FEATURE = 'OPERATION_MODE';

interface IProps {
  operationModeId: string;
}

const OperationModeDetail: React.FC<IProps> = ({ operationModeId }) => {
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();
  const { getListCompanies, companies } = useMasterData();

  const handleCreateDevice = () => {
    setCurrentData(undefined);
    setIsModalCreateOpen(true);
  };

  const [isModalCreateOpen, setIsModalCreateOpen] = React.useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = React.useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = React.useState(false);
  const [currentData, setCurrentData] =
    React.useState<OperationModeDetailProperties>();
  const [dataAsset, setDataAsset] = React.useState<AssetProperties[]>([]);
  const [forceDeleteId, setForceDeleteId] = React.useState<string>();

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setQuery,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
    fetchList,
  } = useFetchList<OperationModeDetailProperties>({
    httpRequest: httpRequest as any,
    endpoint: 'operation-mode-detail',
    limit: +PAGE_SIZE_OPTIONS[1],
    initialQuery: {
      operationModeId,
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  async function deleteData() {
    try {
      if (currentData) {
        const forceDelete =
          forceDeleteId && forceDeleteId === currentData.operationModeDetailId;
        await httpRequest.delete(
          '/operation-mode-detail/' + currentData.operationModeDetailId,
          {
            params: {
              forceDelete: forceDelete || undefined,
            },
          }
        );
        message.success(
          `Success ${forceDelete ? 'delete' : 'archive'} operation mode detail`
        );

        setQuery({
          ...query,
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalDeleteOpen(false);
      setCurrentData(undefined);
    }
  }

  async function restoreData() {
    try {
      if (currentData) {
        await httpRequest.patch(
          '/operation-mode-detail/' +
            currentData.operationModeDetailId +
            '/restore'
        );
        message.success(`Success restore operation mode detail`);

        setQuery({
          ...query,
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalRestoreOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function handleRestoreDevice(id: any) {
    setIsModalRestoreOpen(true);
    const found = data.find((item) => item.operationModeDetailId === id);
    if (found) {
      setCurrentData(found);
    }
  }

  async function handleDeleteDevice(id: any, forced?: boolean) {
    setIsModalDeleteOpen(true);
    const found = data.find((item) => item.operationModeDetailId === id);
    if (found) {
      setCurrentData(found);
    }

    if (forced) {
      setForceDeleteId(id);
    }
  }

  function handleCloseModal() {
    setIsModalDeleteOpen(false);
    setIsModalRestoreOpen(false);
    setIsModalCreateOpen(false);
    setIsModalEditOpen(false);
    setCurrentData(undefined);
  }

  function isArchived(id?: string) {
    if (id) {
      const found = data.find(
        (item) => item.operationModeDetailId === id && item.deletedAt
      );
      if (found) {
        return true;
      }
    }

    return false;
  }

  const filterCompanyOptions = React.useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [{ value: '', label: 'All' }, ...data];
  }, [companies]);

  const columns: IAppTableColumn<OperationModeDetailProperties>[] = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (value, records) => (
        <LinkTable
          onPress={() => {
            setCurrentData(records);
            setIsModalEditOpen(true);
          }}
        >
          {records.deletedAt ? (
            <Tag color='#ff5500' className='mb-2'>
              Archived
            </Tag>
          ) : (
            false
          )}
          {records?.name}
        </LinkTable>
      ),
    },
    {
      title: 'RPM MAX',
      dataIndex: 'rpmMax',
      key: 'rpmMax',
      render: (value, records) => <div>{records?.rpmMax}</div>,
    },
    {
      title: 'CONST RATE',
      dataIndex: 'consumptionRate',
      key: 'consumptionRate',
      render: (value, records) => <div>{records?.consumptionRate} L/m</div>,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (value, _) => (
        <Tag color={value === 'active' ? 'success' : 'error'}>
          {value.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, _) =>
        formatCurrentDate(value, EFormatCurrentDate.DEFAULT_SHORT_MONTH),
    },
    {
      title: 'ACTION',
      key: 'action',
      type: 'actions',
      actions: [
        isUserHasPermission([FEATURE + '.UPDATE'])
          ? {
              key: 'update',
              label: 'Update',
              onPress: (id) => {
                const found = data.find(
                  (item) => item.operationModeDetailId === id
                );
                if (found) {
                  setCurrentData(found);
                  setIsModalEditOpen(true);
                }
              },
            }
          : undefined,
        isUserHasPermission([FEATURE + '.DELETE'])
          ? {
              key: 'archive',
              label: 'Delete',
              color: '#ff4d4f',
              disabled: (id) => isArchived(id),
              disabledTooltip: 'This data is already archived',
              onPress: (id) => handleDeleteDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.RESTORE'])
          ? {
              key: 'restore',
              label: 'Restore',
              color: '#1890ff',
              disabled: (id) => !isArchived(id),
              disabledTooltip: 'This data not in archived',
              onPress: (id) => handleRestoreDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.FORCE_DELETE'])
          ? {
              key: 'delete',
              label: 'Force Delete',
              onPress: (id) => handleDeleteDevice(id, true),
            }
          : undefined,
      ],
    },
  ];

  const filterData: any = [
    {
      type: 'search',
      key: 'search',
      label: 'Search',
      placeholder: 'Search by name',
      onChange: (value: any) => {
        handleSearch(value);
      },
      colSpan: isUserHasPermission([FEATURE + '.DELETE']) ? 6 : 10,
    },
    {
      type: 'select',
      key: 'status',
      label: 'Status',
      options: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterStatus: value === 'all' ? '' : value,
        });
      },
      colSpan: 6,
      allowClear: false,
    },
    {
      type: 'select',
      key: 'filterData',
      label: 'Filter Data',
      options: [
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'show',
          label: 'Include Archive Data',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterArchived: value === 'default' ? '' : value,
        });
      },
      colSpan: 4,
      allowClear: false,
    },
  ].filter((item) =>
    !isUserHasPermission([FEATURE + '.DELETE'])
      ? item.key !== 'filterData'
      : item
  );

  return (
    <React.Fragment>
      <FilterWrapper>
        <RowFilterWrapper>
          <RowFilter
            filterValues={{
              search: pageQueries.search,
              status: pageQueries.status || 'all',
              filterData: pageQueries.filterData || 'default',
            }}
            filters={[filterData]}
          />
        </RowFilterWrapper>
        <Button type='primary' onClick={handleCreateDevice}>
          Add Constant
        </Button>
      </FilterWrapper>

      <AppTable
        isLoading={isLoading}
        keyId='operationModeDetailId'
        columns={columns}
        data={data}
        size='small'
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />

      <Modal
        title={
          forceDeleteId ? 'Force Delete Confirmation' : 'Delete Confirmation'
        }
        open={isModalDeleteOpen}
        onOk={deleteData}
        okButtonProps={{
          style: {
            background: 'red',
          },
        }}
        onCancel={handleCloseModal}
      >
        {forceDeleteId ? (
          <p>
            Are you sure want to permanently delete this data? This data will be
            deleted and only can't be undo.
          </p>
        ) : (
          <p>
            Are you sure want to delete this data? This data will be archived
            and only User with restore permission that can undo this action.
          </p>
        )}
      </Modal>

      <Modal
        title='Restore Confirmation'
        open={isModalRestoreOpen}
        onOk={restoreData}
        okButtonProps={{
          style: {
            background: 'blue',
          },
        }}
        onCancel={handleCloseModal}
      >
        <p>Are you sure want to restore this data?.</p>
      </Modal>

      <Modal
        title='Create New Constant'
        open={isModalCreateOpen}
        onCancel={handleCloseModal}
        footer={''}
        bodyStyle={{
          marginTop: 20,
        }}
      >
        <OperationModeDetailCreate
          operationModeId={operationModeId}
          onClose={() => {
            fetchList();
            handleCloseModal();
          }}
        />
      </Modal>

      <Modal
        title='Edit Constant'
        open={isModalEditOpen}
        onCancel={handleCloseModal}
        footer={''}
      >
        {currentData ? (
          <OperationModeDetailEdit
            operationModeId={operationModeId}
            operationModeDetailId={currentData.operationModeDetailId}
            onClose={handleCloseModal}
          />
        ) : (
          <div>No data available</div>
        )}
      </Modal>
    </React.Fragment>
  );
};

const RowFilterWrapper = styled.div`
  > div {
    > div {
      width: 300px !important;
    }
  }
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
`;

export default OperationModeDetail;
