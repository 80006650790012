import { generateQueryString, getErrorMessage } from '@qlibs/react-components';
import React, { useState } from 'react';
import { httpRequest } from '../helpers/api';
import { CompanyProperties } from '../screens/company/types/company.type';
import { GroupProperties } from '../screens/group/types/group.type';
import { AssetProperties } from '../screens/asset/types/asset.type';
import { DeviceProperties } from '../screens/device/types/device.type';
import { createGlobalState } from 'react-hooks-global-state';

interface IMasterData {
  loadingGroup: boolean;
  loadingCompany: boolean;
  loadingAsset: boolean;
  loadingDevice: boolean;
  loadingOperationMode: boolean;
  isOperationMode: boolean;
  groups: GroupProperties[];
  companies: CompanyProperties[];
  assets: AssetProperties[];
  devices: DeviceProperties[];
}

const initialState: IMasterData = {
  groups: [],
  companies: [],
  assets: [],
  devices: [],
  loadingGroup: false,
  loadingCompany: false,
  isOperationMode: false,
  loadingAsset: false,
  loadingDevice: false,
  loadingOperationMode: false,
};

const { useGlobalState } = createGlobalState(initialState);

const useMasterData = () => {
  const [groups, setGroups] = useGlobalState('groups');
  const [companies, setCompanies] = useGlobalState('companies');
  const [assets, setAssets] = useGlobalState('assets');
  const [devices, setDevices] = useGlobalState('devices');
  const [loadingGroup, setLoadingGroup] = useGlobalState('loadingGroup');
  const [loadingCompany, setLoadingCompany] = useGlobalState('loadingCompany');
  const [loadingAsset, setLoadingAsset] = useGlobalState('loadingAsset');
  const [loadingDevice, setLoadingDevice] = useGlobalState('loadingDevice');
  const [loadingOperationMode, setLoadingOperationMode] = useGlobalState(
    'loadingOperationMode'
  );
  const [isOperationMode, setIsOperationMode] =
    useGlobalState('isOperationMode');

  const getListGroup = async (params?: any) => {
    try {
      setLoadingGroup(true);
      const res = await httpRequest.get(`/group${generateQueryString(params)}`);

      if (res.data.payload.results) {
        setGroups(res.data.payload.results);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setLoadingGroup(false);
    }
  };

  const getListCompanies = async (params?: any) => {
    try {
      setLoadingCompany(true);
      const res = await httpRequest.get(
        `/company${generateQueryString(params)}`
      );

      if (res.data.payload.results) {
        setCompanies(res.data.payload.results);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setLoadingCompany(false);
    }
  };

  const getListAssets = async (params?: any) => {
    try {
      setLoadingAsset(true);
      const res = await httpRequest.get(`/asset${generateQueryString(params)}`);

      if (res.data.payload.results) {
        setAssets(res.data.payload.results);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setLoadingAsset(false);
    }
  };

  const getListDevices = async (params?: any) => {
    try {
      setLoadingDevice(true);
      const res = await httpRequest.get(
        `/device${generateQueryString(params)}`
      );

      if (res.data.payload.results) {
        setDevices(res.data.payload.results);
      }
    } catch (error) {
      console.error(getErrorMessage(error));
    } finally {
      setLoadingDevice(false);
    }
  };

  return {
    groups,
    companies,
    assets,
    devices,
    isOperationMode,

    loadingGroup,
    loadingCompany,
    loadingAsset,
    loadingDevice,
    loadingOperationMode,

    setLoadingGroup,
    setLoadingCompany,
    setLoadingAsset,
    setLoadingDevice,
    setLoadingOperationMode,
    setIsOperationMode,

    setGroups,
    setCompanies,
    setAssets,
    setDevices,

    getListGroup,
    getListCompanies,
    getListAssets,
    getListDevices,
  };
};

export default useMasterData;
