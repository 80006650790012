import {
  DownOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Menu,
  MenuProps,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { generateInitialFromName } from '../helpers/generator';
import { getFullName } from '../helpers/name';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import NotificationList from './NotificationList';
import { capitalizeFirstLetter, removeToken } from '@qlibs/react-components';
import { ListLangType } from '../i18n/settings';
import { useCookies } from 'react-cookie';
import { useTranslationCustom } from '../i18n/client';
import IdIcon from '../assets/icons/id.png';
import EnIcon from '../assets/icons/en.png';
import JpIcon from '../assets/icons/jp.png';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../redux/action/language';
import useLanguage from '../zustand/useLanguage';
import { ReactComponent as IconGSMActive } from '../assets/icons/icon-gsm-active.svg';
import { ReactComponent as IconGSMInActive } from '../assets/icons/icon-gsm-inactive.svg';
import { ReactComponent as IconSatelliteActive } from '../assets/icons/icon-satellite-active.svg';
import { ReactComponent as IconSatelliteInActive } from '../assets/icons/icon-satellite-inactive.svg';
import { ReactComponent as IconInfo } from '../assets/icons/icon-info.svg';
import useGroup from '../zustand/useGroup';
import { httpRequest } from '../helpers/api';

const { Title } = Typography;

const UserAvatar = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { feature, dataSource, setDataSource, setFeature } = useGroup();
  // const lng = useSelector((state: any) => state.language.language);
  const { lng, setLng } = useLanguage();

  const location = useLocation();
  const { pathname } = location;

  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  const [loading, setLoading] = useState<boolean>(false);

  const [languageSelected, setLanguageSelected] = useState<ListLangType>(lng);

  const { t, i18n } = useTranslationCustom(lng, 'language');

  const initial = generateInitialFromName(auth()?.name!);
  const role = capitalizeFirstLetter(auth()?.role.roleName);
  let name = auth()?.name ? getFullName(auth()) : '';
  name = name
    ? name
        .split(' ')
        .map((value: any) => capitalizeFirstLetter(value))
        .join(' ')
    : '';

  const handleMenuClick = async (e: any) => {
    if (e.key === 'profile') {
      navigate('/profile');
    } else if (e.key === 'change-password') {
      navigate('/change-password');
    } else {
      try {
        setLoading(true);
        await httpRequest.post('/auth/signout');
        signOut();
        removeToken();
        localStorage.removeItem('group-selected');
        localStorage.removeItem('map-settings');
        navigate('/login');
      } catch (error) {
        console.error('failed signout', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const items: MenuProps = {
    onClick: handleMenuClick,
    items: [
      {
        key: '1',
        type: 'group',
        label:
          'Welcome, ' +
          (auth()?.name!.length > 15
            ? auth()?.name!.substring(0, 15) + '...'
            : auth()?.name),
        children: [
          {
            key: 'profile',
            icon: <UserOutlined />,
            label: 'My Profile',
          },
          {
            key: 'change-password',
            icon: <LockOutlined />,
            label: 'Change Password',
          },
          {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: 'Logout',
          },
        ],
      },
    ],
  };

  const itemLanguages: MenuProps['items'] = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'en',
      label: 'EN',
    },
    {
      key: 'jp',
      label: 'JP',
    },
  ];

  const handleMarkAllAsRead = () => {
    //
  };

  const handleLanguageChange = (selectedKeys: React.Key[]) => {
    const selectedLanguage = selectedKeys[0] as 'id' | 'en';
    setLanguageSelected(selectedLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(languageSelected);
    setCookie('i18next', languageSelected);
    setLng(languageSelected);
  }, [languageSelected]);

  useEffect(() => {
    if (cookies?.i18next) {
      setLanguageSelected(cookies?.i18next);
    }
  }, [cookies?.i18next]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 14,
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Spin spinning={loading}>
        <Dropdown menu={items}>
          <div
            style={{
              display: 'flex',
              gap: 8,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <AppAvatar style={{ backgroundColor: '#0e2153', color: 'white' }}>
              {initial}
            </AppAvatar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 1.5,
              }}
            >
              <div
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {name.length > 30 ? name.substring(0, 28) + '...' : name}
              </div>
              <div
                style={{
                  marginBottom: 0,
                  fontSize: 10,
                  color: '#768499',
                }}
              >
                {role}
              </div>
            </div>

            <Icon />
          </div>
        </Dropdown>
      </Spin>
    </div>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 7px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;
