import React from 'react';
import { Divider, Image, Slider, Switch } from 'antd';
import useMapSetting from '../../../zustand/useMap';
import { ReactComponent as IconVesselOnline } from '../../../assets/icons/home/icon-vessel-online.svg';
import { ReactComponent as IconVesselOffline } from '../../../assets/icons/home/icon-vessel-offline.svg';
import { ReactComponent as IconVesselWaiting } from '../../../assets/icons/home/icon-vessel-waiting.svg';
import { ReactComponent as IconVesselLabel } from '../../../assets/icons/home/icon-vessel-label.svg';
import { ReactComponent as IconVesselTrail } from '../../../assets/icons/home/icon-vessel-trail.svg';
import { ReactComponent as IconDotTrail } from '../../../assets/icons/home/icon-dot-trail.svg';
import MapSatellite from '../../../assets/icons/home/map-satellite.png';
import MapVector from '../../../assets/icons/home/map-vector.png';

const MapSettings = () => {
  const {
    showVesselOnline,
    showVesselOffline,
    showVesselWaiting,
    showVesselLabel,
    showVesselTrail,
    showDotTrail,
    trailWidth,
    trailOpacity,
    trailLength,
    mapType,
    setShowVesselOnline,
    setShowVesselOffline,
    setShowVesselWaiting,
    setShowVesselLabel,
    setShowVesselTrail,
    setShowDotTrail,
    setTrailWidth,
    setTrailOpacity,
    setTrailLength,
    setMapType,
  } = useMapSetting();

  const _renderSwitchSetting = (icon: any, label: string, value: boolean) => {
    const handleSwitchChange = (label: string, val: boolean) => {
      const labelMap: Record<string, (val: boolean) => void> = {
        'Show Online Vessel': setShowVesselOnline,
        'Show Offline Vessel': setShowVesselOffline,
        'Show Waiting Data Vessel': setShowVesselWaiting,
        'Show Vessel Label': setShowVesselLabel,
        'Show Vessel Trail': setShowVesselTrail,
        'Show Dot Trail': setShowDotTrail,
      };

      const handler = Object.keys(labelMap).find((key) => label.includes(key));

      if (handler && labelMap[handler]) {
        labelMap[handler](val);
      }
    };

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 15,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {icon}
          <div>{label}</div>
        </div>

        <Switch
          size='small'
          checked={value}
          onChange={(val: boolean) => handleSwitchChange(label, val)}
        />
      </div>
    );
  };

  const _renderRangeSetting = (
    label: string,
    value: any,
    min: any,
    max: any,
    step: number = 1
  ) => {
    return (
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div>{label}</div>
          <div
            style={{
              backgroundColor: 'rgb(118, 132, 153)',
              padding: '4px 6px 4px 6px',
              borderRadius: 5,
              textAlign: 'center',
              color: 'white',
            }}
          >
            {value}
          </div>
        </div>
        <Slider
          min={min}
          max={max}
          defaultValue={value}
          step={step}
          onChange={(e) => {
            if (label.includes('Width')) {
              setTrailWidth(e);
            } else if (label.includes('Opacity')) {
              setTrailOpacity(e);
            } else {
              setTrailLength(e);
            }
          }}
        />
      </div>
    );
  };

  const _renderMapSetting = (
    icon: any,
    label: 'Satellite' | 'Vector',
    value: string
  ) => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => setMapType(label)}>
        <Image
          preview={false}
          fallback='/images/empty-image.png'
          src={icon}
          width={60}
          height={60}
          style={{
            borderRadius: label === value ? 13 : 0,
            border: label === value ? '2px solid #264284' : 'none',
          }}
        />
        <p
          style={{
            marginTop: 8,
            textAlign: 'center',
            color: label === value ? '#264284' : 'black',
          }}
        >
          {label}
        </p>
      </div>
    );
  };

  return (
    <div style={{ marginTop: 10, padding: 10, overflowY: 'auto' }}>
      <div>
        <p style={{ color: '#748494' }}>Vessel Status</p>
        {_renderSwitchSetting(
          <IconVesselOnline />,
          'Show Online Vessel',
          showVesselOnline
        )}
        {_renderSwitchSetting(
          <IconVesselOffline />,
          'Show Offline Vessel',
          showVesselOffline
        )}
        {_renderSwitchSetting(
          <IconVesselWaiting />,
          'Show Waiting Data Vessel',
          showVesselWaiting
        )}
      </div>

      <Divider />

      <div>
        <p style={{ color: '#748494' }}>Vessel Element</p>
        {_renderSwitchSetting(
          <IconVesselLabel />,
          'Show Vessel Label',
          showVesselLabel
        )}
        {_renderSwitchSetting(
          <IconVesselTrail />,
          'Show Vessel Trail',
          showVesselTrail
        )}
        {_renderSwitchSetting(<IconDotTrail />, 'Show Dot Trail', showDotTrail)}
      </div>

      <Divider />

      <div>
        <p style={{ color: '#748494' }}>Trail Settings</p>
        {_renderRangeSetting('Trail Width', trailWidth, 1, 5)}
        {_renderRangeSetting('Trail Opacity', trailOpacity, 0.5, 1, 0.1)}
        {_renderRangeSetting('Trail Length', trailLength, 24, 72, 24)}
      </div>

      <Divider />

      <div>
        <p style={{ color: '#748494' }}>Map Type</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 15,
          }}
        >
          {_renderMapSetting(MapSatellite, 'Satellite', mapType)}
          {_renderMapSetting(MapVector, 'Vector', mapType)}
        </div>
      </div>
    </div>
  );
};

export default MapSettings;
