import { useMemo } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import useGroup from '../../../../zustand/useGroup';
import ReportChart from '../reportChart';
import {
  convertIntervalToSecond,
  generateDataSeriesTable,
  generateTimestampData,
  toHHMM,
} from '../helperFunction';
import { EReportDataInterval } from '../../types/report-log.type';

interface IProps {
  isReportSchedule?: {
    startAt: number;
    endAt: number;
    interval: any;
    deviceType: string;
    assetId: string;
  };
}

const schema = [
  {
    logKey: 'ae',
    dataKeys: ['ae1', 'runningTime'],
    seriesOption: {
      name: 'AE 1 Running Time',
      type: 'line',
      yAxis: 0,
      zIndex: 0,
      marker: {
        enabled: false,
        fillColor: 'rgb(2, 204, 204)',
      },
      fillColor: 'rgb(2, 204, 204)',
      lineColor: 'rgb(2, 204, 204)',
      tooltip: {
        valueSuffix: ' hour',
      },
    },
  },
  {
    logKey: 'ae',
    dataKeys: ['ae2', 'runningTime'],
    seriesOption: {
      name: 'AE 2 Running Time',
      type: 'line',
      yAxis: 0,
      zIndex: 1,
      marker: {
        enabled: false,
        fillColor: 'rgb(111, 87, 233)',
      },
      lineColor: 'rgb(111, 87, 233)',
      tooltip: {
        valueSuffix: ' hour',
      },
    },
  },
  {
    logKey: 'ae',
    dataKeys: ['ae3', 'runningTime'],
    seriesOption: {
      name: 'AE 3 Running Time',
      type: 'line',
      yAxis: 0,
      zIndex: 1,
      marker: {
        enabled: false,
        fillColor: 'rgb(255, 162, 22)',
      },
      lineColor: 'rgb(255, 162, 22)',
      tooltip: {
        valueSuffix: ' hour',
      },
    },
  },
];

const ChartAERunningTime: React.FC<IProps> = ({ isReportSchedule }) => {
  const { logs } = useReportLog();
  const { filter } = useGroup();

  const dataTable = useMemo(() => {
    const dataSeriesTable: any[] = [[...Array(24).keys()]];

    let intervalInSecond = convertIntervalToSecond(isReportSchedule?.interval);

    const timestamps = generateTimestampData({
      startTime: isReportSchedule?.startAt!,
      endTime: isReportSchedule?.endAt!,
      interval: isReportSchedule?.interval,
      intervalInSecond,
    });

    schema.forEach((schema) => {
      if ((logs as any)[schema.logKey]) {
        const res = generateDataSeriesTable({
          timestamps,
          key: schema.dataKeys,
          fm: (logs as any)[schema.logKey],
        });

        dataSeriesTable.push(res.map((item) => toHHMM(item)));
      }
    });

    return dataSeriesTable;
  }, [schema]);

  const tableColumns = useMemo(() => {
    let res: any[] = ['Time'];
    schema.forEach((item) => {
      res.push(item.seriesOption.name);
    });

    return res;
  }, [schema]);

  return (
    <>
      <ReportChart
        logs={logs}
        filter={isReportSchedule || filter}
        yAxis={['time']}
        chartSchema={schema}
        isReportSchedule={
          isReportSchedule && isReportSchedule.deviceType ? true : false
        }
      />
      {isReportSchedule && tableColumns.length && (
        <table className='table-app'>
          <tbody>
            {dataTable.map((data, idx) => (
              <tr key={idx}>
                <td>{tableColumns[idx]}</td>
                {data.map((item: number | undefined, idx2: number) => (
                  <td key={idx2}>{typeof item === 'undefined' ? '-' : item}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ChartAERunningTime;
