import { Card, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import styled from 'styled-components';
import {
  calculateGpsSummary,
  generateReportOperationModeSummary,
  generateReportSummary,
  generateTimestampData,
  toHHMMSS,
} from '../helperFunction';
import useGroup from '../../../../zustand/useGroup';
import { EAssetType } from '../../../asset/types/asset.type';
import { EEmailBodyParams } from '../../../masterReport/types/report.type';
import { EReportDataInterval } from '../../types/report-log.type';
import {
  cruiseUnitOptions,
  distanceUnitOptions,
  speedUnitOptions,
} from '../../../../helpers/unit';
import { httpRequest } from '../../../../helpers/api';
import {
  BaseResponsePaginationProps,
  capitalizeFirstLetter,
  generateQueryString,
} from '@qlibs/react-components';
import {
  OperationModeDetailProperties,
  OperationModeProperties,
} from '../../../operationMode/types/operationMode.type';

interface IProps {
  isReportSchedule?: boolean;
}

const SummaryOperationModeTable: React.FC<IProps> = (props) => {
  const { logs } = useReportLog();
  const { filter } = useGroup();
  const [loading, setLoading] = useState<boolean>(false);
  const [opModes, setOpModes] = useState<OperationModeDetailProperties[]>([]);

  useEffect(() => {
    if (
      logs &&
      logs.operation_mode &&
      Object.keys(logs.operation_mode).length
    ) {
      async function fetchOperationMode() {
        try {
          setLoading(true);
          const devicesIds: string[] = [];
          const port = Object.values(logs.operation_mode!)[0]['port'];
          const starboard = Object.values(logs.operation_mode!)[0]['starboard'];

          if (port && starboard) {
            devicesIds.push(port.deviceUniqueId);
            devicesIds.push(starboard.deviceUniqueId);
          }

          const res = await httpRequest.get<
            BaseResponsePaginationProps<OperationModeProperties>
          >(
            `/operation-mode${generateQueryString({
              devicesIds,
              limit: 10,
            })}`
          );

          if (res.data.payload.results && res.data.payload.results.length) {
            const result: OperationModeDetailProperties[] = [];
            res.data.payload.results.forEach((item) => {
              if (
                item.operationModeDetails &&
                item.operationModeDetails.length
              ) {
                item.operationModeDetails?.forEach((el) => {
                  result.push(el);
                });
              }
            });

            setOpModes(result);
          }
        } catch (error) {
          console.error('Failed get operation mode details', error);
        } finally {
          setLoading(false);
        }
      }

      fetchOperationMode();
    }
  }, []);

  const dataSummaryOp = useMemo(() => {
    const result = generateReportOperationModeSummary({
      startAt: filter.startAt,
      endAt: filter.endAt,
      interval: filter.interval,
      logs,
      opModes,
    });

    const totalRunningTime = result.reduce(
      (acc, curr) => acc + curr.runningTime,
      0
    );
    const totalConsRate = result.reduce((acc, curr) => acc + curr.consRate, 0);

    return {
      result,
      totalRunningTime,
      totalConsRate,
    };
  }, [logs, opModes]);

  return (
    <div id='page-summary'>
      <Wrapper>
        <Table2>
          <Tr titleSection>
            <Td>Operation Mode</Td>
            <Td>Running Time</Td>
            <Td>Fuel Cons.</Td>
          </Tr>
          {dataSummaryOp.result.map((item, idx) => (
            <Tr key={idx}>
              <Td>{item.name.toUpperCase()}</Td>
              <Td>{toHHMMSS(item.runningTime)}</Td>
              <Td>{item.consRate.toFixed(2)}</Td>
            </Tr>
          ))}
          <Tr titleSection>
            <Td>Total</Td>
            <Td>{toHHMMSS(dataSummaryOp.totalRunningTime)}</Td>
            <Td>{dataSummaryOp.totalConsRate.toFixed(2)}</Td>
          </Tr>
        </Table2>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{ column?: number }>`
  display: flex;
  column-gap: 20px;
  grid-template-columns: ${({ column: gridColumn }) =>
    gridColumn ? `repeat(0,${gridColumn}fr)` : 'repeat(0, 2fr)'};
`;

const Table2 = styled.table`
  margin-top: 30px;
  width: 100%;
`;

const Tr = styled.tr<{ titleSection?: boolean }>`
  background-color: ${({ titleSection }) =>
    titleSection ? 'var(--gray-2)' : 'white'};
  font-weight: ${({ titleSection }) => (titleSection ? 'bold' : 'normal')};
`;

const Td = styled.td<{ unit?: boolean; value?: boolean }>`
  border-top: thin solid #ddd;
  border-bottom: thin solid #ddd;
  width: 10%;
  padding: 4px 8px;
  font-weight: ${({ value }) => (value ? 'bold' : 'regular')};
  color: ${({ unit }) => (unit ? 'gray' : 'black')};
`;

const CardInfo = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;

    div {
      :first-child {
        opacity: 0.7;
      }
      :last-child {
        font-weight: bold;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const Accordion = styled.div`
  margin-top: 15px;
  grid-column: 4;
`;

const CardInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: 20px;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export default SummaryOperationModeTable;
