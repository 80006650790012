import { useEffect, useMemo, useState } from 'react';
import {
  convertData,
  convertIntervalToSecond,
  generateTimestampData,
} from './helperFunction';
import { generateReportOperationModeColumns } from './tableColumn';
import useGroup from '../../../zustand/useGroup';
import useReportLog from '../../../hooks/useReportLog';
import { Divider, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import TableConfiguration from './tableConfiguration';
import usePermission from '../../../hooks/usePermission';
import styled from 'styled-components';
import { useVT } from 'virtualizedtableforantd4';
import SummaryOperationModeTable from './summaryOperationModeTable';

const windowHeight = window.innerHeight;
export interface RowData {
  [key: string]: string | number | null;
}

const TableReportOperationMode = () => {
  const { isUserHasPermission } = usePermission();
  const { filter } = useGroup();
  const {
    setChecklistColumn,
    checklistColumn,
    loadingLog: loading,
    logs,
  } = useReportLog();
  const [columns, setColumns] = useState<any>([]);
  const [vt] = useVT(() => ({ scroll: { x: 700, y: windowHeight - 300 } }), []);

  useEffect(() => {
    if (logs && Object.keys(logs).length) {
      const res = generateReportOperationModeColumns(logs).map((item: any) => {
        if (item.children) {
          return {
            ...item,
            hidden: false,
            children: item.children.map((el: any) => {
              return {
                ...el,
                hidden: false,
              };
            }),
          };
        } else {
          return {
            ...item,
            hidden: false,
          };
        }
      });

      setColumns(res);
    }
  }, [logs]);

  useEffect(() => {
    if (columns && columns.length) {
      const checklist: string[] = [];

      columns.forEach((item: any) => {
        if (item.children) {
          checklist.push(item.key);
          item.children.forEach((child: any) => {
            checklist.push(child.key);
          });
        } else {
          checklist.push(item.key);
        }
      });

      setChecklistColumn(checklist);
    }
  }, [columns]);

  const columnsFiltered = useMemo(() => {
    const res = columns
      .filter((item: any) => {
        if (checklistColumn.includes(item.key)) {
          return item;
        } else if (item.children) {
          const isParentExist = checklistColumn.find(
            (columnKey) => item.key === columnKey
          );
          if (isParentExist) {
            return {
              ...item,
              children: item.children.filter((child: any) =>
                checklistColumn.includes(child.key)
              ),
            };
          }
        }
      })
      .map((item: any, idx: number) => {
        if (item.children && item.children.length) {
          return {
            ...item,
          };
        } else {
          return item;
        }
      });
    return res;
  }, [columns, checklistColumn]);

  const dataSource = useMemo(() => {
    if (logs && Object.keys(logs).length) {
      let intervalInSecond = convertIntervalToSecond(filter.interval);

      const containerTimestamp = generateTimestampData({
        startTime: filter.startAt,
        endTime: filter.endAt,
        interval: filter.interval,
        intervalInSecond,
      });

      const results = containerTimestamp.map((timestamp) => {
        let opMode;
        if (logs.operation_mode) {
          const foundKey = Object.keys(logs.operation_mode).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            opMode = logs.operation_mode[foundKey];
          }
        }

        return {
          timestamp,
          dateTime: dayjs(timestamp * 1000).format('DD MMM YYYY HH:mm'),
          port_RpmMax: convertData(opMode?.port?.rpmMax),
          port_Mode: opMode?.port?.mode,
          port_ConsRate: convertData(opMode?.port?.consRate),
          starboard_RpmMax: convertData(opMode?.starboard?.rpmMax),
          starboard_Mode: opMode?.starboard?.mode,
          starboard_ConsRate: convertData(opMode?.starboard?.consRate),
        };
      });

      return results;
    }
  }, [logs]);

  return (
    <React.Fragment>
      <SummaryOperationModeTable />

      <Table
        loading={loading}
        className='table-report-operation-mode'
        columns={columnsFiltered}
        dataSource={dataSource}
        size='small'
        bordered
        pagination={{
          pageSize: 500,
        }}
        components={vt}
        scroll={{ x: 700, y: windowHeight - 300 }}
      />
    </React.Fragment>
  );
};

const ContainerFilterTable = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default TableReportOperationMode;
