import { Button, Checkbox, Collapse, Popover, Space } from 'antd';
import React from 'react';
import useReportLog from '../../../hooks/useReportLog';
import { capitalizeFirstLetter } from '@qlibs/react-components';
import { DownOutlined, TableOutlined } from '@ant-design/icons';

interface IProps {
  columns: any[];
}

const TableConfiguration: React.FC<IProps> = ({ columns }) => {
  const { checklistColumn, setChecklistColumn } = useReportLog();

  const _renderCheckboxColumn = () => {
    const onChangeChildren = (childKey: string) => {
      if (!checklistColumn.includes(childKey)) {
        setChecklistColumn([...checklistColumn, childKey]);
      } else {
        const newData = checklistColumn.filter(
          (item) => ![childKey].includes(item)
        );
        setChecklistColumn(newData);
      }
    };

    const onChangeParent = (parentKey: string) => {
      const parent = columns.find((item: any) => item.key === parentKey);
      let childrens: string[] = [];
      if (parent.children) {
        childrens = parent.children.map((item: any) => item.key);
      }

      if (!checklistColumn.includes(parentKey)) {
        setChecklistColumn([...checklistColumn, ...childrens, parentKey]);
      } else {
        const newData = checklistColumn.filter(
          (item) => ![...childrens, parentKey].includes(item)
        );
        setChecklistColumn(newData);
      }
    };

    const items = columns?.map((item: any, idx: number) => {
      let checkboxOption: { label: string; value: string }[] = [];

      if (item.children) {
        checkboxOption = item.children.map((el: any) => ({
          label: capitalizeFirstLetter(el.title),
          value: el.key,
        }));
      }

      return {
        key: idx,
        showArrow: item.children ? true : false,
        label: (
          <Checkbox
            key={item.key}
            disabled={item.key === 'dateTime'}
            checked={checklistColumn.includes(item.key)}
            onChange={() => onChangeParent(item.key)}
          >
            {capitalizeFirstLetter(item.title)}
          </Checkbox>
        ),
        children:
          item.children &&
          item.children.map((item: any) => {
            return (
              <Checkbox
                key={item.key}
                checked={checklistColumn.includes(item.key)}
                onChange={() => onChangeChildren(item.key)}
              >
                {capitalizeFirstLetter(item.title)}
              </Checkbox>
            );
          }),
      };
    });

    return (
      <Collapse
        className='checkbox-table-report-log'
        collapsible={'icon'}
        items={items}
        size='small'
        style={{ width: 300 }}
      />
    );
  };

  return (
    <React.Fragment>
      <Popover
        content={_renderCheckboxColumn()}
        trigger='hover'
        overlayInnerStyle={{
          width: 320,
        }}
        placement='bottomRight'
      >
        <Button color='default' type='primary' icon={<TableOutlined />}>
          <Space>
            Show/Hide Column
            <DownOutlined />
          </Space>
        </Button>
      </Popover>
    </React.Fragment>
  );
};

export default TableConfiguration;
