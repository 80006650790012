import { Card, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import styled from 'styled-components';
import {
  calculateGpsSummary,
  generateReportSummary,
  toHHMMSS,
} from '../helperFunction';
import useGroup from '../../../../zustand/useGroup';
import { EAssetType } from '../../../asset/types/asset.type';
import { EEmailBodyParams } from '../../../masterReport/types/report.type';
import {
  cruiseUnitOptions,
  distanceUnitOptions,
  speedUnitOptions,
} from '../../../../helpers/unit';
import useMasterData from '../../../../hooks/useMasterData';
import SummaryOperationModeTable from '../summaryOperationModeTable';

interface IProps {
  isReportSchedule?: boolean;
}

const SummaryTable: React.FC<IProps> = (props) => {
  const {
    logs,
    distanceUnit,
    setDistanceUnit,
    setSpeedUnit,
    speedUnit,
    setCruiseUnit,
    cruiseUnit,
  } = useReportLog();
  const { filter, asset } = useGroup();
  const { isOperationMode } = useMasterData();

  const dataSource = useMemo(() => {
    return generateReportSummary({
      startAt: filter.startAt,
      endAt: filter.endAt,
      interval: filter.interval,
      logs,
    });
  }, [logs]);

  const gpsSummmary = useMemo(() => {
    return calculateGpsSummary(logs, {
      distanceUnit,
      speedUnit,
      cruiseUnit,
    });
  }, [logs, distanceUnit, speedUnit, cruiseUnit]);

  return (
    <div id='page-summary'>
      <Wrapper>
        <Table2>
          <Tr titleSection>
            <Td colSpan={6}>Summary</Td>
          </Tr>
          <Tr>
            <Td>Total Cruise</Td>
            <Td value id={EEmailBodyParams.gps_total_cruise}>
              {gpsSummmary.totalCruise}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={distanceUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setCruiseUnit(e)}
                  options={cruiseUnitOptions}
                />
              ) : (
                'KM'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Average Speed 123</Td>
            <Td value id={EEmailBodyParams.gps_average_speed}>
              {gpsSummmary.avgSpeed}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={speedUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setSpeedUnit(e)}
                  options={speedUnitOptions}
                />
              ) : (
                'KM/H'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Distance</Td>
            <Td value id={EEmailBodyParams.gps_distance}>
              {gpsSummmary.totalDistance}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={distanceUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setDistanceUnit(e)}
                  options={distanceUnitOptions}
                />
              ) : (
                'KM'
              )}
            </Td>
          </Tr>
        </Table2>

        <Table2>
          <Tr titleSection>
            <Td colSpan={6}>Fuel Consumption Summary</Td>
          </Tr>
          <Tr>
            <Td>Engine Fuel Cons.</Td>
            <Td value id={EEmailBodyParams.total_engine_fuel_cons}>
              {dataSource.totalEngineFuelCons.toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE Fuel Cons.</Td>
            <Td value id={EEmailBodyParams.total_ae_fuel_cons}>
              {dataSource.totalAEFuelCons.toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
          <Tr>
            <Td>Total Fuel Cons.</Td>
            <Td value id={EEmailBodyParams.total_fuel_cons}>
              {(
                dataSource.totalEngineFuelCons + dataSource.totalAEFuelCons
              ).toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
        </Table2>
      </Wrapper>

      {![EAssetType.EMS].includes(asset.assetType) && (
        <Wrapper column={3}>
          <Table2>
            <Tr titleSection>
              <Td colSpan={6}>Engine Running Time</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td value id={EEmailBodyParams.engine_port_running_time}>
                {toHHMMSS(dataSource.portRunningHour)}
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td value id={EEmailBodyParams.engine_starboard_running_time}>
                {toHHMMSS(dataSource.starboardRunningHour)}
              </Td>
              <Td></Td>
            </Tr>
          </Table2>

          <Table2>
            <Tr titleSection>
              <Td colSpan={6}>Engine Fuel Consumption</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td value id={EEmailBodyParams.engine_port_fuel_cons}>
                {dataSource.portTotalFuel.toFixed(2)}
              </Td>
              <Td unit>Liter</Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td value id={EEmailBodyParams.engine_starboard_fuel_cons}>
                {dataSource.starboardTotalFuel.toFixed(2)}
              </Td>
              <Td unit>Liter</Td>
            </Tr>
          </Table2>

          <Table2>
            <Tr titleSection>
              <Td colSpan={6}>Engine Speed</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td value id={EEmailBodyParams.engine_port_rpm}>
                {dataSource.portRPM.toFixed(2)}
              </Td>
              <Td unit>RPM</Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td value id={EEmailBodyParams.engine_starboard_rpm}>
                {dataSource.starboardRPM.toFixed(2)}
              </Td>
              <Td unit>RPM</Td>
            </Tr>
          </Table2>
        </Wrapper>
      )}

      <Wrapper>
        <Table2>
          <Tr titleSection>
            <Td colSpan={6}>AE Running Time</Td>
          </Tr>
          <Tr>
            <Td>AE1</Td>
            <Td value id={EEmailBodyParams.ae_1running_time}>
              {toHHMMSS(dataSource.ae1RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
          <Tr>
            <Td>AE2</Td>
            <Td value id={EEmailBodyParams.ae_2running_time}>
              {toHHMMSS(dataSource.ae2RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
          <Tr>
            <Td>AE3</Td>
            <Td value id={EEmailBodyParams.ae_3running_time}>
              {toHHMMSS(dataSource.ae3RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
        </Table2>

        <Table2>
          <Tr titleSection>
            <Td colSpan={6}>AE Fuel Consumption</Td>
          </Tr>
          <Tr>
            <Td>AE1</Td>
            <Td value id={EEmailBodyParams.ae_1fuel_cons}>
              {dataSource.ae1TotalFuel.toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE2</Td>
            <Td value id={EEmailBodyParams.ae_2fuel_cons}>
              {dataSource.ae2TotalFuel.toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE3</Td>
            <Td value id={EEmailBodyParams.ae_3fuel_cons}>
              {dataSource.ae3TotalFuel.toFixed(2)}
            </Td>
            <Td unit>Liter</Td>
          </Tr>
        </Table2>
      </Wrapper>

      {isOperationMode && !props.isReportSchedule && (
        <SummaryOperationModeTable />
      )}
    </div>
  );
};

const Wrapper = styled.div<{ column?: number }>`
  display: flex;
  column-gap: 20px;
  grid-template-columns: ${({ column: gridColumn }) =>
    gridColumn ? `repeat(0,${gridColumn}fr)` : 'repeat(0, 2fr)'};
`;

const Table2 = styled.table`
  margin-top: 30px;
  width: 100%;
`;

const Tr = styled.tr<{ titleSection?: boolean }>`
  background-color: ${({ titleSection }) =>
    titleSection ? 'var(--gray-2)' : 'white'};
  font-weight: ${({ titleSection }) => (titleSection ? 'bold' : 'normal')};
`;

const Td = styled.td<{ unit?: boolean; value?: boolean }>`
  border-top: thin solid #ddd;
  border-bottom: thin solid #ddd;
  width: 10%;
  padding: 4px 8px;
  font-weight: ${({ value }) => (value ? 'bold' : 'regular')};
  color: ${({ unit }) => (unit ? 'gray' : 'black')};
`;

const CardInfo = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;

    div {
      :first-child {
        opacity: 0.7;
      }
      :last-child {
        font-weight: bold;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const Accordion = styled.div`
  margin-top: 15px;
  grid-column: 4;
`;

const CardInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: 20px;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export default SummaryTable;
